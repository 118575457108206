import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IDialog, IDialogPagination} from "../../common/types/dialog";
import {IPagination} from "../../common/types/pagination";
import api from "../../api/baseApi";

export interface DialogsState {
  dialogs: IDialog[];
  currentDialog?: IDialog | null;
  pagination: IPagination;
  loading: boolean;
  error: string | null;
}
export

const initialState: DialogsState = {
  dialogs: [],
  currentDialog: null,
  loading: false,
  error: null,
  pagination: {
    "page": "1",
    "take": "1",
    "itemCount": 0,
    "pageCount": 0,
    "hasPreviousPage": false,
    "hasNextPage": false
  },
};

interface FetchDialogsParams {
  customerId?: string;
  page: number;
  quantityOnPage: number;
  search: string;
}

export const fetchDialogs = createAsyncThunk(
    'users',
    async ({ customerId, page, quantityOnPage, search }: FetchDialogsParams, {rejectWithValue}) => {
      try {
        const result: IDialogPagination = await api(`/customers/dialogs?customerId=${customerId}&page=${page}&take=${quantityOnPage}&name=${search}`, {
          headers: {
            credentials: "include",
          },
        });
        return result;
      } catch (error: any) {
        return rejectWithValue(error.message || "Something went wrong");
      }
    }
);

export const dialogsSlice = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    setDialogs: (state, action: PayloadAction<IDialogPagination>) => {
      state.dialogs = action.payload.data;
      state.pagination = action.payload.pagination;
    },
    setCurrentDialog: (state, action: PayloadAction<IDialog>) => {
      state.currentDialog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchDialogs.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchDialogs.fulfilled, (state, action: PayloadAction<IDialogPagination>) => {
          state.dialogs = action.payload.data;
          state.pagination = action.payload.pagination;
          state.loading = false;
        })
        .addCase(fetchDialogs.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        });
  },
});

export const { setDialogs, setCurrentDialog } = dialogsSlice.actions;

export const DialogReducer = dialogsSlice.reducer;
