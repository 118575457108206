import { FC, useMemo } from "react";
import Avatar from "../../../../components/media-components/Avatar";
import { IDialog } from "../../../../common/types/dialog";
import BackArrowNavigate from "../../../../components/buttons/BackArrowNavigate";
import DialogNavButton from "./DialogNavButton";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import ChatIcon from "../../../../assets/icons/ChatIcon";
import PictureIcon from "../../../../assets/icons/PictureIcon";
import VideoIcon from "../../../../assets/icons/VideoIcon";
import DocumentIcon from "../../../../assets/icons/DocumentIcon";
import AudioFile from "../../../../assets/icons/AudioFile";

interface IMessagesHeaderProps {
  dialog: IDialog;
  customerId: string | undefined;
}

const MessagesHeader: FC<IMessagesHeaderProps> = ({ dialog, customerId }) => {

  const dialogsData = useAppSelector((state) => state.dialogs.dialogs);

  const dialogPhone = useMemo(()=>{
    return dialog.phone || dialogsData.find((d) => d.tgId === dialog.tgId)?.phone
  },[dialog.phone, dialogsData, dialog.tgId])

  return (
    <div className={"flex pb-5 justify-between items-center"}>
      <div className={"flex gap-x-5 "}>
        <Avatar
          url={dialog.avatarsUrl?.[0] || ""}
          customSize={"w-[50px] h-[50px]"}
          customIconSize={30}
        />
        <div className={"flex flex-col "}>
          <p className={"text-lg font-bold"}>{dialog.name} </p>
          {dialogPhone && (
            <p className="text-xs text-gray-500">
              Номер телефона {dialogPhone}
            </p>
          )}
          <p className={"text-xs text-gray-500"}>TG_id{dialog.tgId} </p>
        </div>
      </div>
      <div className={"flex gap-x-3 items-center"}>
        <DialogNavButton
          href={`/customers/${customerId}/data/dialogs/${dialog.id}`}
          icon={<ChatIcon />}
        />
        <DialogNavButton
          href={`/customers/${customerId}/data/dialogs/${dialog.id}/images`}
          icon={<PictureIcon />}
        />
        <DialogNavButton
          href={`/customers/${customerId}/data/dialogs/${dialog.id}/videos`}
          icon={<VideoIcon />}
        />
        <DialogNavButton
          href={`/customers/${customerId}/data/dialogs/${dialog.id}/documents`}
          icon={<DocumentIcon />}
        />
        <DialogNavButton
          href={`/customers/${customerId}/data/dialogs/${dialog.id}/audio`}
          icon={<AudioFile />}
        />
      </div>

      <BackArrowNavigate
        backUrl={`/customers/${customerId}/data/all-dialogs`}
      />
    </div>
  );
};

export default MessagesHeader;
