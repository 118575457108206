import React, {FC, useEffect, useState} from 'react';

interface BufferVideoComponentProps {
    src: string;
    videoType: string;
    className?: string;
}

const BufferVideoComponent: FC<BufferVideoComponentProps> = ({src, videoType, className}) => {
    const [videoUrl, setVideoUrl] = useState<string | null>(null);

    useEffect(() => {
        let url:any
        try {
             const byteCharacters = atob(src);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'video/webm'});

        url = URL.createObjectURL(blob);
        setVideoUrl(url);
        } catch (error) {
            console.error("Failed to decode Base64 string:", error);
        }
        return () => {
            URL.revokeObjectURL(url);
        };
    }, [src]);

    if (!videoUrl) {
        return <div>Loading video...</div>;
    }

    return (
        <video controls className={` w-full h-full max-w-[250px] max-h-[250px] pb-2 rounded-md  ${className}`}>
            <source src={videoUrl} type="video/mp4"/>
        </video>
    );
};

export default BufferVideoComponent;