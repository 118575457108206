import { useRef, useEffect, useState } from "react";
import { IMessage } from "../../../../common/types/message";
import MessageContainer from "../messages-components/MessageContainer";
import Message from "../messages-components/Message";
import { MyPagination } from "../../../../common/types/plagination";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import api from "../../../../api/baseApi";
import {
  setMessages,
  updateMessageMedia,
} from "../../../../store/slices/messages-slice";
import { IDialog } from "../../../../common/types/dialog";
import { useAppDispatch } from "../../../../hooks/reduxHooks";
import { ITelegramUser } from "../../../../common/types/telegram-user";
import { useLocation, useParams } from "react-router-dom";
import CookieHandler from "./CookieHandler";
import classes from './commonPagesStyles.module.css';

const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
};

const getScrollCookieKey = (dialogId: string) => `scrollPosition_${dialogId}`;

const MessagesWindow = () => {
  const dispatch = useAppDispatch();
  const messages = useAppSelector((state) => state.messages.messages);
  const dialog: IDialog = useAppSelector(
    (state) => state.dialogs.currentDialog
  ) as IDialog;
  const { id } = useParams<{ id: string }>();
  const customerId = id;
  const [query, setQuery] = useState<MyPagination>({
    page: 1,
    limit: 100,
  });

  const getCurrentPageCookieKey = () => `"dialogPage_messages_${customerId}_${dialog.tgId}"`;

  const messagesWindowRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLastPageReached, setIsLastPageReached] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mediaId = searchParams.get("imageId");

  const handleScroll = () => {
    if (messagesWindowRef.current) {
      const position = messagesWindowRef.current.scrollTop;
      setScrollPosition(position);
    }
  };

  const saveScrollPosition = () => {
    if (messagesWindowRef.current && dialog) {
      const currentScrollPosition = messagesWindowRef.current.scrollTop;
      const cookieKey = getScrollCookieKey(dialog.tgId);

      const savedPosition = getCookie(cookieKey);

      if (
        savedPosition === undefined ||
        parseInt(savedPosition, 10) !== currentScrollPosition
      ) {
        setCookie(cookieKey, currentScrollPosition.toString(), 7);
      }
    }
  };

  const restoreScrollPosition = () => {
    if (messagesWindowRef.current && dialog && !mediaId) {
      const cookieKey = getScrollCookieKey(dialog.tgId);
      const savedPosition = getCookie(cookieKey);

      if (savedPosition !== undefined) {
        const position = parseInt(savedPosition, 10);
        if (messagesWindowRef.current.scrollTop !== position) {
          messagesWindowRef.current.scrollTop = position;
        }
      }
    }
  };

  const fetchMessages = async (page: number = query.page, limit: number = query.limit) => {
    if (isLastPageReached) {
      return;
    }
    setIsLoading(true);
    try {
      const url = `/customers/messages?dialogId=${dialog.tgId}&customerId=${customerId}&page=${page}&limit=${limit}`;

      const messagesData: any = await api(url,
        {
          headers: {
            credentials: "include",
          },
        }
      );

      const newMessages = messagesData.messages as IMessage[];
      if (newMessages.length < (query.limit)) {
        setIsLastPageReached(true);
      }

      if (newMessages.length > 0) {
        const allMessages = page === 1
          ? newMessages
          : [...(messages || []), ...newMessages];

        dispatch(
          setMessages({
            messages: allMessages,
            total: messagesData.total,
          })
        );
      }
    } catch (error) {
      console.log("Error fetching messages", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNewMediaURL = async (dialogId: string, messageId: string) => {
    try {
      const response: any = await api(`/customers/medias/${dialogId}/${messageId}`, {
        headers: {
          credentials: "include",
        },
      });
      const newMedia = response;
      if (newMedia) {
        dispatch(updateMessageMedia({ messageId, newMedia }));
      }
    } catch (error) {
      console.log("Error loading new media", error);
    }
  };

  const handleLoadMore = () => {
    if (!isLoading && !isLastPageReached) {
      setQuery((prev) => {
        const newPage = prev.page + 1;
        CookieHandler.setCookie(getCurrentPageCookieKey(), `${newPage}`, 1);
        return { ...prev, page: newPage };
      });
    }
  };

  useEffect(() => {
    const currentPageCookieValue = CookieHandler.getCookieInt(getCurrentPageCookieKey());
    if (currentPageCookieValue !== undefined && currentPageCookieValue > 1 && query.page < currentPageCookieValue) {
      fetchMessages(1, (currentPageCookieValue - 1) * query.limit)
        .then(async () => {
          await new Promise((resolve) => setTimeout(resolve, 500));
          setQuery((prev) => ({ ...prev, page: currentPageCookieValue }));
        })
    } else {
      fetchMessages(query.page, query.limit);
    }
  }, [query.page]);

  const getMediaPlaceholder = (message: IMessage): string | null => {
    if (!message.text && (!message.media || message.media)) {
      return "";
    }
    return null;
  };

  useEffect(() => {
    const divRef = messagesWindowRef.current;

    if (divRef) {
      divRef.addEventListener("scroll", handleScroll);
      divRef.addEventListener("scroll", saveScrollPosition);
    }
    return () => {
      if (divRef) {
        divRef.removeEventListener("scroll", handleScroll);
        divRef.removeEventListener("scroll", saveScrollPosition);
      }
    };
    //eslint-disable-next-line
  }, [handleScroll]);

  useEffect(() => {
    if (!isLoading && messages.length > 0 && mediaId) {
      const messageElement = document.getElementById(`message-${mediaId}`);
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isLoading, mediaId, messages]);

  useEffect(() => {
    if (!isLoading && messages.length > 0) {
      restoreScrollPosition();
    }
    //eslint-disable-next-line
  }, [isLoading, messages.length]);

  if (!messages.length) {
    return <div>Сообщения отсутствуют</div>;
  }

  const handleThumbnailClick = async (dialogId: string, messageId: string) => {
    await fetchNewMediaURL(dialogId, messageId);
  };

  return (
    <>
      <div
        ref={messagesWindowRef}
        className={"flex flex-col-reverse gap-y-2 max-h-[600px] overflow-auto"}
      >
        {messages.map((message: IMessage) => {
          const sender: ITelegramUser = dialog.users?.find(
            (user) => user.tgId === message.senderId
          ) as ITelegramUser;

          const mediaPlaceholder = getMediaPlaceholder(message);

          return (
            <MessageContainer
              customerId={customerId}
              senderId={message.senderId || ""}
              key={message.tgId}
              id={`message-${message.tgId}`}
            >
              <Message
                message={message}
                sender={sender}
                placeholder={mediaPlaceholder}
                handleThumbnailClick={handleThumbnailClick}
              />
            </MessageContainer>
          );
        })}
        <div>
          {!isLastPageReached && (
            <div className={`${classes.loadMoreContainer} !mt-[0] !mb-[20px]`}>
              <button
                disabled={isLoading}
                onClick={handleLoadMore}
                className={classes.loadMoreButton}
              >
                {isLoading && <div>Загрузка...</div>}
                {!isLoading && <div>Загрузить ещё</div>}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MessagesWindow;
