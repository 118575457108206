import {IMedia} from "../../common/types/media";
import {setSelectedImage} from "../../store/slices/selectedImage-slice";
import {useDispatch} from "react-redux";

interface BufferImageProps {
    media: IMedia
}

export const BufferImage = ({media}: BufferImageProps) => {
    const dispatch = useDispatch();

    return (
        <img
            onClick={() => dispatch(setSelectedImage(`data:image/jpeg;base64,${media}`))}
            src={`data:image/jpeg;base64,${media.media}`}
            alt=""
            className={'w-52 h-52 cursor-pointer p-2 rounded-md hover:bg-[#3d3e3f] transition-colors'}
        />
    )
}