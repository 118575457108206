import { withDynamicStyling } from "./components/BaseHOC";
import Router from "./router/Router";
import { BrowserRouter } from "react-router-dom";

function App() {
  const RouterComponent = withDynamicStyling(Router);
  return (
    <div className="flex w-full justify-center items-center bg-[#fff]">
      <BrowserRouter
        future={{ v7_relativeSplatPath: true, v7_startTransition: true }}
      >
        <RouterComponent />
      </BrowserRouter>
    </div>
  );
}

export default App;
