import React, { useState } from "react";

interface CustomersFilterProps {
  selectedFilters?: string[];
  setSelectedFilters: (filters: string[]) => void;
}

const filters = [
  { value: "available", label: "Только активные" },
  { value: "ru", label: "Только русские" },
  { value: "ua", label: "Только украинские" },
];

export default function FilterComponent({
  selectedFilters,
  setSelectedFilters,
}: CustomersFilterProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (value: string) => {
    setSelectedFilters(
      selectedFilters?.includes(value)
        ? selectedFilters.filter((filterValue: string) => filterValue !== value)
        : [...(selectedFilters || []), value]
    );
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={toggleDropdown}
        >
          Выберите фильтры
          <svg
            className={`-mr-1 ml-2 h-5 w-5 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 mb-4 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {filters.map((filter) => (
              <label
                key={filter.value}
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  checked={selectedFilters?.includes(filter.value)}
                  onChange={() => handleCheckboxChange(filter.value)}
                />
                <span className="ml-2">{filter.label}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
