import { useEffect, useState, useCallback } from "react";
import { IMedia, MediaType } from "../../../common/types/media";
import api from "../../../api/baseApi";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import BackArrowNavigate from "../../../components/buttons/BackArrowNavigate";
import { Video } from "../../../components/media-components/Video";
import { MediaQuery } from "../../../common/types/plagination";
import { useParams } from "react-router-dom";
import CookieHandler from "./CookieHandler";
import classes from './commonPagesStyles.module.css';

const CustomerVideosPage = () => {
  const [videos, setVideos] = useState<IMedia[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLastPageReached, setIsLastPageReached] = useState(false);
  const { id } = useParams<{ id: string }>();

  const [query, setQuery] = useState<MediaQuery>({
    page: 1,
    limit: 10,
    mediaType: MediaType.VIDEO,
  });

  const getCurrentPageCookieKey = () => `"currentPage_media_${id}_${query.mediaType}"`;

  const fetchVideos = useCallback(
    async (page: number, limit: number) => {
      if (isLastPageReached) {
        return;
      }
      setIsLoading(true);
      try {
        const url = `/customers/media/${id}?mediaType=${query.mediaType}&page=${page}&limit=${limit}`;

        const result = await api(url, {
          method: "GET",
          headers: {
            credentials: "include",
          },
        });

        const newVideos = result as IMedia[];
        if (newVideos.length < (query.limit)) {
          setIsLastPageReached(true);
        }

        setVideos((prevVideos) =>
          page === 1
            ? newVideos
            : [...prevVideos, ...newVideos]
        );
      } catch (error) {
        console.log("Error fetching videos", error);
      } finally {
        setIsLoading(false);
      }
    },
    [id, query.limit]
  );

  const fetchNewMediaURL = async (dialogId: string, messageId: string) => {
    try {
      const response: any = await api(`/customers/medias/${dialogId}/${messageId}`, {
        headers: {
          credentials: "include",
        },
      });
      const newMedia = response;
      if (newMedia) {
        setVideos((prevVideos) =>
          prevVideos.map((video) =>
            video.messageId === messageId ? { ...video, url: newMedia } : video
          )
        );
      }
    } catch (error) {
      console.log("Error downloading new media", error);
    }
  };

  const handleLoadMore = () => {
    if (!isLoading && !isLastPageReached) {
      setQuery((prev) => {
        const newPage = prev.page + 1;
        CookieHandler.setCookie(getCurrentPageCookieKey(), `${newPage}`, 1);
        return { ...prev, page: newPage };
      });
    }
  };

  const handleThumbnailClick = async (dialogId: string, messageId: string) => {
    await fetchNewMediaURL(dialogId, messageId);
  };

  useEffect(() => {
    const currentPageCookieValue = CookieHandler.getCookieInt(getCurrentPageCookieKey());
    if (currentPageCookieValue !== undefined && currentPageCookieValue > 1 && query.page < currentPageCookieValue) {
      fetchVideos(1, (currentPageCookieValue - 1) * query.limit)
        .then(async () => {
          await new Promise((resolve) => setTimeout(resolve, 500));
          setQuery((prev) => ({ ...prev, page: currentPageCookieValue }));
        })
    } else {
      fetchVideos(query.page, query.limit);
    }
  }, [fetchVideos, query.page]);

  if (isLoading && query.page === 1) {
    return (
      <ContentContainer>
        <CustomersInfoCard customPadding={"p-[30px]"}>
          <div>Идет загрузка видео, это может занять некоторое время...</div>
        </CustomersInfoCard>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <CustomersInfoCard customPadding={"p-[30px]"}>
        <div className={"flex flex-col w-full min-h-[600px]"}>
          <div className={"w-full flex justify-end gap-x-3 py-3"}>
            <div className={"flex items-center gap-x-2"}>
              <BackArrowNavigate backUrl={`/customers/${id}/data/files`} />
            </div>
          </div>
          <div className={`${classes.scrollableContainer} flex flex-wrap gap-5 w-full h-full justify-start`}>
            {videos.map((video, index) => (
              <Video
                media={video}
                key={index}
                handleThumbnailClick={handleThumbnailClick}
              />
            ))}
          </div>
          {!isLastPageReached && (
            <div className={classes.loadMoreContainer}>
              <button
                disabled={isLoading}
                onClick={handleLoadMore}
                className={classes.loadMoreButton}
              >
                {isLoading && <div>Загрузка...</div>}
                {!isLoading && <div>Загрузить ещё</div>}
              </button>
            </div>
          )}
        </div>
      </CustomersInfoCard>
    </ContentContainer>
  );
};

export default CustomerVideosPage;
