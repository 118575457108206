import SelectMenuItemButton from "./SelectMenuItemButton";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { ICustomer } from "../../../common/types/customer";
import VideoIcon from "../../../assets/icons/VideoIcon";
import PictureIcon from "../../../assets/icons/PictureIcon";
import DocumentIcon from "../../../assets/icons/DocumentIcon";
import AudioFile from "../../../assets/icons/AudioFile";

const FileMenuPage = () => {
  const customer = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;
  const { tgId } = customer;
  return (
    <div className={"flex flex-col gap-y-2 w-full min-w-[800px]"}>
      <SelectMenuItemButton
        href={`/customers/${tgId}/data/files/photos`}
        title={"Фотографии"}
        subtitle={"Все"}
        icon={<PictureIcon />}
      />
      <SelectMenuItemButton
        href={`/customers/${tgId}/data/files/videos`}
        title={"Видео"}
        subtitle={"Все"}
        icon={<VideoIcon />}
      />
      <SelectMenuItemButton
        href={`/customers/${tgId}/data/files/documents`}
        title={"Документы"}
        subtitle={"Все"}
        icon={<DocumentIcon />}
      />
      <SelectMenuItemButton
        href={`/customers/${tgId}/data/files/audio`}
        title={"Аудиофайлы"}
        subtitle={"Все"}
        icon={<AudioFile />}
      />
    </div>
  );
};

export default FileMenuPage;
