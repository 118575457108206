import {Route, Routes} from "react-router-dom";
import QrLoginPage from "../pages/customer-pages/QrLoginPage";
import PhoneLoginPage from "../pages/customer-pages/PhoneLoginPage";
import AdminLogin from "../pages/admin-pages/auth/AdminLogin";
import CustomersPage from "../pages/admin-pages/customers/CustomersPage";
import AdminRoute from "./AdminRoute/AdminRoute";
import AllDialogsPage from "../pages/admin-pages/dialogs/AllDialogsPage";
import PublicRoute from "./PublicRoute/PublicRoute";
import DialogPage from "../pages/admin-pages/dialog/DialogPage";
import ConfirmCodePage from "../pages/customer-pages/ConfirmCodePage";
import ConfirmPasswordPage from "../pages/customer-pages/ConfirmPasswordPage";
import CustomerInfoPage from "../pages/admin-pages/customer-info/CustomerInfoPage";
import CustomerInfoLayout from "../components/layouts/CustomerInfoLayout";
import FileMenuPage from "../pages/admin-pages/medias/FileMenuPage";
import ContactsPage from "../pages/admin-pages/contacts/ContactsPage";
import MessagesWindow from "../pages/admin-pages/dialog/dialog-windows/MessagesWindow";
import DialogImages from "../pages/admin-pages/dialog/dialog-windows/DialogImages";
import DialogDocuments from "../pages/admin-pages/dialog/dialog-windows/DialogDocuments";
import DialogVideos from "../pages/admin-pages/dialog/dialog-windows/DialogVideos";
import CustomerPhotosPage from "../pages/admin-pages/medias/CustomerPhotosPage";
import CustomerDocumentsPage from "../pages/admin-pages/medias/CustomerDocumentsPage";
import CustomerVideosPage from "../pages/admin-pages/medias/CustomerVideosPage";
import CustomerAudioPage from "../pages/admin-pages/medias/CustomerAuidoPage";
import DialogAudio from "../pages/admin-pages/dialog/dialog-windows/DialogAudio";
import CustomerLinksPage from "../pages/admin-pages/customer-links/CustomerLinksPage";
import RedirectUrlPage from "../pages/customer-pages/RedirectUrlPage";
import UsersPage from "../pages/admin-pages/users/UsersPage";
import CheckElevatedRight from "../Middlewares/CheckElevatedRight";
// import BanlistChannelsPage from "../pages/admin-pages/banlistChannels/BanlistChannelsPage";

export default function Router() {
    return (
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<QrLoginPage />} />
          <Route path="/t" element={<PhoneLoginPage />} />
          <Route path="/c" element={<ConfirmCodePage />} />
          <Route path="/p" element={<ConfirmPasswordPage />} />
          <Route path="/admin/sign-in" element={<AdminLogin />} />
          <Route path={"/:hash"} element={<RedirectUrlPage />} />
        </Route>

        <Route element={<AdminRoute />}>
          <Route path="/customers" element={<CustomersPage />} />
          <Route path="/customers-links" element={<CustomerLinksPage />} />
          <Route
            path="/users"
            element={<CheckElevatedRight element={<UsersPage />} />}
          />
          <Route path="/dialogs" element={<AllDialogsPage />} />
          <Route path="/messages" element={<DialogPage />} />
          <Route path="/customers/:id" element={<CustomerInfoPage />} />
          <Route path="/customers/:id/data" element={<CustomerInfoLayout />}>
            <Route path="all-dialogs" element={<AllDialogsPage />} />
            <Route path="files" element={<FileMenuPage />} />
            <Route path={"contacts"} element={<ContactsPage />} />
          </Route>
          <Route
            path="/customers/:id/data/files/photos"
            element={<CustomerPhotosPage />}
          />
          <Route
            path="/customers/:id/data/files/documents"
            element={<CustomerDocumentsPage />}
          />
          <Route
            path="/customers/:id/data/files/videos"
            element={<CustomerVideosPage />}
          />
          <Route
            path="/customers/:id/data/files/audio"
            element={<CustomerAudioPage />}
          />
          <Route path="/customers/:id/data/dialogs/" element={<DialogPage />}>
            <Route path=":dialogId" element={<MessagesWindow />} />
            <Route path=":dialogId/images" element={<DialogImages />} />
            <Route path=":dialogId/documents" element={<DialogDocuments />} />
            <Route path=":dialogId/videos" element={<DialogVideos />} />
            <Route path=":dialogId/audio" element={<DialogAudio />} />
          </Route>
        </Route>
      </Routes>
    );
}
