import React, { FC, useEffect, useState } from "react";
import { IMedia } from "../../common/types/media";
import PlayIcon from "../../assets/icons/PlayIcon";
import DownloadIcon from "../../assets/icons/DownloadIcon";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { setSelectedVideo } from "../../store/slices/selectedVideo-slice";
import videoThumbnailClasses from "./UrlVideo.module.css";

interface BufferVideoComponentProps {
  media: IMedia;
  className?: string;
  clickableThumbnail?: boolean;
  handleThumbnailClick?: (dialogId: string, messageId: string) => void;
}

const UrlVideo: FC<BufferVideoComponentProps> = ({
  media,
  className,
  clickableThumbnail = true,
  handleThumbnailClick,
}) => {
  const dispatch = useAppDispatch();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    if (media?.url) {
      setIsVideoPlaying(media.url.endsWith(".mp4"));
    }
  }, [media?.url]);

  const onPlayClick = () => {
    setIsVideoPlaying(true);
    if (handleThumbnailClick) {
      handleThumbnailClick(media.dialogTgId, media.messageId);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = media.downloadLink ?? '';
    link.download = media?.downloadLink?.split("/").pop() || "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const videoUrl = (media.url as string) + '?v=1';

  const onThumbnailClick = (e: React.MouseEvent<HTMLElement>) => {
    if (clickableThumbnail) {
      dispatch(setSelectedVideo(media));
    } else {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const thumbnailClasses = clickableThumbnail
    ? `w-full h-full rounded-md cursor-pointer p-2 rounded-md hover:bg-[#3d3e3f] transition-colors ${className}`
    : `w-full h-full rounded-md cursor-default p-2 rounded-md ${className}`;

  return (
    <div className="relative w-full h-full max-w-[250px] max-h-[250px] pb-2 rounded-md">
      {isVideoPlaying ? (
        <video
          key={videoUrl}
          controls
          preload="metadata"
          className={`w-full h-full max-w-[250px] max-h-[250px] rounded-md ${className}`}
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          src={media.url}
          alt="Thumbnail"
          className={thumbnailClasses}
          onClick={onThumbnailClick}
        />
      )}

      {!isVideoPlaying && (
        <div className={`absolute inset-0 flex items-center opacity-75 justify-center text-white ${videoThumbnailClasses.thumbnailControls}`}>
          <div
            title="Download"
            className="cursor-pointer"
            onClick={handleDownload}
          >
            <DownloadIcon />
          </div>

          <div
            title="Play"
            className="flex items-center justify-center text-white opacity-75 cursor-pointer"
            onClick={onPlayClick}
          >
            <PlayIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default UrlVideo;
