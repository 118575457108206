import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/loader/Loader";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

window.onerror = function (message, source, lineno, colno, error) {
  if (message === "Script error.") {
    return true;
  }
  console.error("Error:", message, "at", source, ":", lineno, ":", colno);
  return true;
};

window.addEventListener("error", function (e) {
  e.preventDefault();
  console.error(
    "Error:",
    e.message,
    "at",
    e.filename,
    ":",
    e.lineno,
    ":",
    e.colno
  );
});

window.addEventListener("unhandledrejection", function (event) {
  console.error("Unhandled rejection:", event.reason);
});

const RootComponent: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    const preloader = document.getElementById("preloader");
    if (preloader) {
      preloader.style.display = "none";
    }
  }, []);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <div className="flex items-center justify-center w-full h-screen">
            {loading ? (
              <Loader text="Проверка подлинности аккаунта..." />
            ) : (
              <App />
            )}
          </div>
          <ToastContainer />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

root.render(<RootComponent />);

reportWebVitals();
