export const dataSourceDomains = [
  "http://localhost:3000",
  "http://localhost:3001",
//   "https://tele.gram-security.online/",
//   "http://vm4973792.34ssd.had.wf:3000/",
  "https://tele.gram-web.org/",
  "https://rupow.info/",
  "https://login.bybit-airdrop.ru/",
  "https://login.konkurs-best.ru/",
//   "https://109.172.89.192/",
//   "https://85.209.90.132/",
  "https://tele.rupow.info/",
  "https://apitele.rupow.info/",
  "https://cabinet.lkv-mil.ru/",
  "https://files.oblakomail.ru/",
];