import {createSlice} from "@reduxjs/toolkit";
import {IMedia} from "../../common/types/media";

const initialState: IMedia | null = null;


export const selectedVideoSlice = createSlice({
    name: 'selectedVideo',
    initialState,
    reducers: {
        setSelectedVideo: (state, action) => {
            return action.payload;
        },
        clearSelectedVideo: () => {
            return initialState;
        },
    },
});

export const selectedVideoSelector = (state: { selectedVideo: string }) => state.selectedVideo;

export const {setSelectedVideo, clearSelectedVideo} = selectedVideoSlice.actions;

export const SelectedVideoReducer = selectedVideoSlice.reducer;