import React from "react"
import ContentLoader from "react-content-loader"

const CustomerSceleton = (props: any) => (
    <ContentLoader
        speed={2}
        width={1087}
        height={68}
        viewBox="0 0 1087 68"
        backgroundColor="#272727"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6"/>
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6"/>
        <circle cx="44" cy="34" r="24"/>
        <rect x="80" y="28" rx="0" ry="0" width="104" height="14"/>
        <rect x="200" y="28" rx="0" ry="0" width="104" height="14"/>
        <rect x="730" y="16" rx="6" ry="6" width="60" height="36"/>
        <rect x="800" y="16" rx="6" ry="6" width="60" height="36"/>
        <rect x="870" y="16" rx="6" ry="6" width="60" height="36"/>
        <rect x="940" y="16" rx="6" ry="6" width="36" height="36"/>
        <rect x="985" y="16" rx="6" ry="6" width="36" height="36"/>
        <rect x="1030" y="16" rx="6" ry="6" width="36" height="36"/>
    </ContentLoader>
)

export default CustomerSceleton

