import React, {FC} from 'react';
import {CustomerLink} from "../../../../common/types/customer-link";
import LinkItem from "./LinkItem";

interface LinksTableProps {
    links: CustomerLink[];
    copyLink: (link: CustomerLink) => Promise<void>;
    deleteLink: (link: CustomerLink) => void;
    updateLink: (link: CustomerLink) => void;
}

const LinksTable: FC<LinksTableProps> = ({links, updateLink, deleteLink, copyLink}) => {
    return (
      <div className={"flex flex-col max-h-[60vh] overflow-y-scroll"}>
        {links.map((link, index) => (
          <LinkItem
            link={link}
            index={index + 1}
            key={link.id}
            copyLink={(link) => copyLink(link)}
            deleteLink={(link) => deleteLink(link)}
            updateLink={(link) => updateLink(link)}
          />
        ))}
      </div>
    );
};

export default LinksTable;