interface MtsPhoneNumberSectionProps {
  openDropdown: boolean;
  countryList: any[];
  handleCountryClick: (country: any) => void;
  phoneNumber: string;
  isError: boolean;
  handlePhoneNumberChange: (event: any) => void;
  onNumberSubmit: () => void;
}

export default function MtsPhoneNumberSection({
  phoneNumber,
  onNumberSubmit,
  handlePhoneNumberChange,
}: MtsPhoneNumberSectionProps) {
  return (
    <>
      <div className="max-w-[380px] w-full flex flex-col items-center relative pb-1">
        <p className="font-bold text-lg text-center mb-2">
          Введите номер телефона учётной записи:
        </p>
      </div>
      <div className="w-full flex max-w-96 flex-col items-center relative text-[rgb(112,117,121)] hover:text-[rgb(51,144,236)] focus:text-[rgb(51,144,236)] my-6">
        <input
          autoFocus
          id="number"
          type="text"
          inputMode="tel"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          className="rounded-xl px-4 py-2 h-12 bg-[#F5F5F5] text-black w-full border border-[#CCCCCC] focus-within:border-black"
        />
      </div>
      <button
        onClick={onNumberSubmit}
        className="rounded-xl px-4 py-2 h-12 bg-[#E30613] w-full font-bold text-sm uppercase text-white"
      >
        Далее
      </button>
    </>
  );
}
