import React, {FC} from 'react';

interface RemoveBanlistChannelDialogWindowProps {
    hide: () => void
    removeBanlistChannel: () => void;
}

const RemoveBanlistChannelDialogWindow: FC<RemoveBanlistChannelDialogWindowProps> = ({hide, removeBanlistChannel}) => {
    return (
        <div
            onClick={hide}
            className={'fixed w-screen h-screen bg-black bg-opacity-70 top-0 cursor-pointer flex justify-center items-center'}>
            <div
                onClick={(e) => e.stopPropagation()}
                className={`bg-[#161616]  text-[#d7d7d7] cursor-default gap-y-5 rounded-md max-w-[400px] px-20 py-10  border-2 border-[#252627] w-full max-h-[600px] flex flex-col`}
            >
                <h3 className={'text-xl font-bold self-start pb-4 text-center'}>
                    Вы действительно хотите удалить канал из бан листа?
                </h3>
                <div className={'flex w-full  h-full gap-x-10 '}>
                    <button
                        onClick={removeBanlistChannel}
                        className={'bg-red-700 font-light transition-colors hover:bg-red-600 px-2 py-1 rounded-md'}
                    >
                        Подтвердить
                    </button>
                    <button
                        onClick={hide}
                        className={'bg-gray-700 transition-colors hover:bg-gray-600 px-2 py-1 rounded-md'}
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RemoveBanlistChannelDialogWindow;