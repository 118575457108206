import React from "react";
import api from "../../api/baseApi";
import { toast } from "react-toastify";
import RepeatIcon from "../../assets/icons/RepeatIcon";

interface ReparseButtonProps {
  customerId: string;
}

const ReparseButton: React.FC<ReparseButtonProps> = ({ customerId }) => {
  const reparseData = async () => {
    try {
      const response = await api(`/reparser/${customerId}`, {
        method: "POST",
      });

      if (response) {
        toast.success("Повторный парсинг данных успешно запущен");
        console.log("Reparse successful:", response);
      }
    } catch (error) {
      toast.error("Ошибка повторного парсинга данных, сессия невалидна");
      console.error("Error reparsing data:", error);
    }
  };

  return (
    <button
      onClick={reparseData}
      title="Повторный запуск парсинга"
      className="w-9 h-9 flex gap-x-2 items-center text-white p-2 transition-colors rounded-lg bg-[#1e3a8a] hover:bg-[#1d4ed8] border border-blue-700 z-30 relative"
    >
      <RepeatIcon />
    </button>
  );
};

export default ReparseButton;
