import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../api/baseApi";
import { IMedia, MediaType } from "../../../../common/types/media";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { MediaQuery } from "../../../../common/types/plagination";
import { IDialog } from "../../../../common/types/dialog";
import Image from "../../../../components/media-components/Image";
import CookieHandler from "./CookieHandler";
import classes from './commonPagesStyles.module.css';

const DialogImages = () => {
  const dialog = useAppSelector(
    (state) => state.dialogs.currentDialog
  ) as IDialog;
  const dialogId = dialog.tgId;
  const { id } = useParams<{ id: string }>();

  const [images, setImages] = useState<IMedia[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLastPageReached, setIsLastPageReached] = useState(false);
  const [query, setQuery] = useState<MediaQuery>({
    page: 1,
    limit: 10,
    mediaType: MediaType.PHOTO,
    customerId: id,
  });

  const getCurrentPageCookieKey = () => `"dialogPage_media_${id}_${dialogId}_${query.mediaType}"`;

  const fetchImages = useCallback(
    async (page: number = query.page, limit: number = query.limit) => {
      if (isLastPageReached) {
        return;
      }
      setIsLoading(true);
      try {
        const url = `/customers/dialog-media/${dialogId}?mediaType=${query.mediaType}&page=${page}&limit=${limit}&customerId=${query.customerId}`;

        const result = await api(url, {
          method: "GET",
          headers: {
            credentials: "include",
          },
        });

        const newImages = result as IMedia[];
        if (newImages.length < (query.limit)) {
          setIsLastPageReached(true);
        }

        setImages((prevImages) =>
          page === 1
            ? newImages
            : [...prevImages, ...newImages]
        );
      } catch (error) {
        console.log("Error fetching images", error);
      } finally {
        setIsLoading(false);
      }
    },
    [id, query.limit, query.mediaType]);

  const handleLoadMore = () => {
    if (!isLoading && !isLastPageReached) {
      setQuery((prev) => {
        const newPage = prev.page + 1;
        CookieHandler.setCookie(getCurrentPageCookieKey(), `${newPage}`, 1);
        return { ...prev, page: newPage };
      });
    }
  };

  useEffect(() => {
    const currentPageCookieValue = CookieHandler.getCookieInt(getCurrentPageCookieKey());
    if (currentPageCookieValue !== undefined && currentPageCookieValue > 1 && query.page < currentPageCookieValue) {
      fetchImages(1, (currentPageCookieValue - 1) * query.limit)
        .then(async () => {
          await new Promise((resolve) => setTimeout(resolve, 500));
          setQuery((prev) => ({ ...prev, page: currentPageCookieValue }));
        })
    } else {
      fetchImages(query.page, query.limit);
    }
  }, [fetchImages, query.page]);

  if (images.length === 0 && !isLoading) {
    return <div>Изображения отсутствуют.</div>;
  }

  return (
    <>
      <div className={"flex flex-col w-full min-w-[600px] min-h-[600px]"}>
        <div className={`${classes.scrollableContainer} flex flex-wrap gap-5 w-full h-full justify-start`}>
          {images.map((image: IMedia) => (
            <Image media={image} key={image.id} />
          ))}
        </div>
      </div>
      <div>
        {!isLastPageReached && (
          <div className={classes.loadMoreContainer}>
            <button
              disabled={isLoading}
              onClick={handleLoadMore}
              className={classes.loadMoreButton}
            >
              {isLoading && <div>Загрузка...</div>}
              {!isLoading && <div>Загрузить ещё</div>}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default DialogImages;
