import { useEffect, useState, useCallback } from "react";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import ContentContainer from "../../../components/layouts/ContentContainer";
import api from "../../../api/baseApi";
import { IMedia, MediaType } from "../../../common/types/media";
import BackArrowNavigate from "../../../components/buttons/BackArrowNavigate";
import { MediaQuery } from "../../../common/types/plagination";
import Image from "../../../components/media-components/Image";
import { useParams } from "react-router-dom";

const CustomerPhotosPage = () => {
  const [images, setImages] = useState<IMedia[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams<{ id: string }>();

  const [query, setQuery] = useState<MediaQuery>({
    page: 1,
    limit: 50,
    mediaType: MediaType.PHOTO,
  });

  const fetchImages = useCallback(
    async (page: number) => {
      try {
        const url = `/customers/media/${id}?mediaType=${query.mediaType}&page=${page}&limit=${query.limit}`;
        const result = await api(url, {
          method: "GET",
          headers: {
            credentials: "include",
          },
        });
        const newImages = result as IMedia[];
        setImages((prevImages) => [...prevImages, ...newImages]);
      } catch (error) {
        console.log("Error fetching images", error);
      } finally {
        setIsLoading(false);
      }
    },
    [id, query.limit, query.mediaType]
  );

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.scrollHeight - 1
    ) {
      setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  }, [isLoading]);

  useEffect(() => {
    fetchImages(query.page);
  }, [fetchImages, query.page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (isLoading && query.page === 1) {
    return (
      <ContentContainer>
        <CustomersInfoCard customPadding={"p-[30px]"}>
          <div>
            Идет загрузка фотографий, это может занять некоторое время...
          </div>
        </CustomersInfoCard>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <CustomersInfoCard customPadding={"p-[30px]"}>
        <div className={"flex flex-col w-full"}>
          <div className={"w-full flex min-w-[600px] justify-end gap-x-3 py-3"}>
            <div className={"flex items-center gap-x-2"}>
              <BackArrowNavigate backUrl={`/customers/${id}/data/files`} />
            </div>
          </div>
          <div className={"flex flex-wrap gap-5 w-full h-full justify-center"}>
            {images.map((image: IMedia) => (
              <Image media={image} key={image.id} />
            ))}
          </div>
          {isLoading && <div>Загрузка...</div>}
          <div style={{ height: "600px" }}></div>
        </div>
      </CustomersInfoCard>
    </ContentContainer>
  );
};

export default CustomerPhotosPage;
