import { IMedia } from "../../common/types/media";
import { setSelectedImage } from "../../store/slices/selectedImage-slice";
import React from "react";
import { useAppDispatch } from "../../hooks/reduxHooks";

interface UrlImageProps {
  media: IMedia;
}

export const UrlImage = ({ media }: UrlImageProps) => {
  const dispatch = useAppDispatch();

  return (
    <img
      onClick={() => dispatch(setSelectedImage(media))}
      src={media.url}
      alt={"media"}
      className={
        "w-52 h-52 cursor-pointer p-2 rounded-md hover:bg-[#3d3e3f] transition-colors"
      }
    />
  );
};
