import { useEffect, useState } from "react";

interface MultiSelectProps {
  options: string[];
  onChange: (option: string[]) => void;
  defaultValue?: string[];
}

export default function MultiSelect({options, onChange, defaultValue}: MultiSelectProps) {
  const [selected, setSelected] = useState<string[]>(defaultValue || []);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const toggleOption = (option: string) => {
    setSelected((prev: string[]) =>
      prev.includes(option)
        ? prev.filter((o: string) => o !== option)
        : [...prev, option]
    );
  };

  const filteredOptions = options.filter((opt) =>
    opt.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(()=>{
    onChange(selected)
    //eslint-disable-next-line
  },[selected])
  
  return (
    <div className="relative w-full">
      <div
        className="border border-gray-300 p-2 rounded cursor-pointer flex flex-wrap gap-1 bg-[#252627]"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selected.length > 0 ? (
          selected.map((item) => (
            <span
              key={item}
              className="bg-blue-500 flex items-center text-white px-2 py-1 rounded text-sm"
            >
              {item}
              <div
                className="ml-1  text-white"
                onClick={() => {
                  toggleOption(item);
                }}
              >
                ×
              </div>
            </span>
          ))
        ) : (
          <span className="text-gray-500">Select options...</span>
        )}
      </div>

      {isOpen && (
        <div className="absolute w-full border border-gray-300 bg-white shadow-lg mt-1 z-10">
          <input
            type="text"
            placeholder="Search..."
            className="w-full p-2 border-b border-gray-200"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="max-h-40 overflow-y-auto">
            {filteredOptions.map((option: any) => (
              <div
                key={option}
                className={`p-2 cursor-pointer text-black hover:bg-blue-500 hover:text-white ${
                  selected.includes(option) ? "bg-blue-100" : ""
                }`}
                onClick={() => {
                  toggleOption(option);
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
