import React, { useEffect, useState, useCallback } from "react";
import { IMedia, MediaType } from "../../../common/types/media";
import api from "../../../api/baseApi";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import BackArrowNavigate from "../../../components/buttons/BackArrowNavigate";
import { Video } from "../../../components/media-components/Video";
import { MediaQuery } from "../../../common/types/plagination";
import { useParams } from "react-router-dom";

const CustomerVideosPage = () => {
  const [videos, setVideos] = useState<IMedia[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams<{ id: string }>();

  const [query, setQuery] = useState<MediaQuery>({
    page: 1,
    limit: 10,
    mediaType: MediaType.VIDEO,
  });

  const fetchVideos = useCallback(
    async (page: number) => {
      setIsLoading(true);
      try {
        const params = new URLSearchParams();
        params.append("mediaType", "video");
        params.append("page", page.toString());
        params.append("limit", query.limit.toString());
        const url = `/customers/media/${id}?mediaType=${
          query.mediaType
        }&page=${page}&limit=${page === 1 ? 50 : query.limit}`;

        const result = await api(url, {
          method: "GET",
          headers: {
            credentials: "include",
          },
        });
        const newVideos = result as IMedia[];
        if (newVideos.length === 0) {
          return;
        }
        setVideos((prevVideos) => [...prevVideos, ...newVideos]);
      } catch (error) {
        console.log("Error fetching videos", error);
      } finally {
        setIsLoading(false);
      }
    },
    [id, query.limit]
  );

  const fetchNewMediaURL = async (messageId: string) => {
    try {
      const response: any = await api(`/customers/medias/${messageId}`, {
        headers: {
          credentials: "include",
        },
      });
      const newMedia = response;
      if (newMedia) {
        setVideos((prevVideos) =>
          prevVideos.map((video) =>
            video.messageId === messageId ? { ...video, url: newMedia } : video
          )
        );
      }
    } catch (error) {
      console.log("Error downloading new media", error);
    }
  };

  const handleThumbnailClick = async (messageId: string) => {
    await fetchNewMediaURL(messageId);
  };

  const handleScroll = useCallback(() => {
    if (
      !isLoading &&
      videos.length > 0 &&
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.scrollHeight - 1
    ) {
      setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  }, [isLoading, videos.length]);

  useEffect(() => {
    fetchVideos(query.page);
  }, [fetchVideos, query.page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (isLoading && query.page === 1) {
    return (
      <ContentContainer>
        <CustomersInfoCard customPadding={"p-[30px]"}>
          <div>Идет загрузка видео, это может занять некоторое время...</div>
        </CustomersInfoCard>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <CustomersInfoCard customPadding={"p-[30px]"}>
        <div className={"min-h-screen w-full flex flex-col"}>
          <div className={"w-full flex justify-end gap-x-3 py-3"}>
            <div className={"flex items-center gap-x-2"}>
              <BackArrowNavigate backUrl={`/customers/${id}/data/files`} />
            </div>
          </div>
          <div
            className={
              "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 w-full h-full justify-start place-items-center"
            }
          >
            {videos.map((video, index) => (
              <Video
                media={video}
                key={index}
                handleThumbnailClick={handleThumbnailClick}
              />
            ))}
          </div>
          {isLoading && <div>Загрузка...</div>}
        </div>
      </CustomersInfoCard>
    </ContentContainer>
  );
};

export default CustomerVideosPage;
