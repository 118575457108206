import React, { FC, useEffect, useState } from "react";
import AddUserForm from "./AddUserForm";
import { IUser } from "../../../../common/types/user";
import { IRole } from "../../../../common/types/role";
import api from "../../../../api/baseApi";
import CancelIcon from "../../../../assets/icons/CancelIcon";

interface UsersDialogWindowProps {
  hideCb: () => void;
  hideAndUpdate: (link: IUser) => void;
  searchValue: string;
}

const AddUserDialogWindow: FC<UsersDialogWindowProps> = ({
  hideCb,
  hideAndUpdate,
    searchValue
}) => {
  const [roles, setRoles] = useState<IRole[]>([]);

  const getRoles = async () => {
    try {
      const roles: IRole[] = await api("roles", {
        headers: {
          credentials: "include",
        },
        method: "GET",
      });
      if (roles) {
        setRoles(roles);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <div
      className={
        "fixed w-screen h-screen bg-black bg-opacity-70 top-0 cursor-pointer flex justify-center items-center"
      }
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-[#161616]  text-[#d7d7d7] cursor-default rounded-md max-w-[750px]  border-2 border-[#252627] w-full max-h-[600px] flex flex-col`}
      >
        <div className={"w-full flex justify-end px-[25px] pt-[25px]"}>
          <button
            onClick={hideCb}
            className={
              "hover:bg-gray-500 transition-colors rounded-md p-2 flex justify-center items-center"
            }
          >
            <CancelIcon />
          </button>
        </div>
        <AddUserForm roles={roles} hideAndUpdate={hideAndUpdate} searchValue={searchValue}/>
      </div>
    </div>
  );
};

export default AddUserDialogWindow;
