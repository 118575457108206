import React, { FC } from "react";
import { Link } from "react-router-dom";
import ChatBubbleSolid from "../../../assets/icons/ChatBubbleSolid";

interface OpenContactDialogButtonProps {
  customerId: string;
  dialogId: string;
}

const OpenContactDialogButton: FC<OpenContactDialogButtonProps> = ({
  customerId,
  dialogId,
}) => {
  const isLinkDisabled = !dialogId;
  const handleLinkClick = (event: React.MouseEvent) => {
    if (isLinkDisabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <Link
      onClick={(event) => handleLinkClick(event)}
      className={`w-[60px]  h-[72px] transition-colors flex justify-center items-center rounded-r-md  ${
        isLinkDisabled
          ? "bg-gray-200 text-gray-500 cursor-default"
          : "bg-[#323435] cursor-pointer"
      } `}
      to={`/customers/${customerId}/data/dialogs/${dialogId}`}
    >
      <ChatBubbleSolid />
    </Link>
  );
};

export default OpenContactDialogButton;
