import NavigationButton from "../buttons/NavigationButton";
import {FaBan, FaUsers} from "react-icons/fa";
import { RiLinksFill } from "react-icons/ri";

import ContentContainer from "./ContentContainer";
import {MdExitToApp} from "react-icons/md";

import {useDispatch} from "react-redux";
import {logout} from "../../store/slices/auth-slice";
import {useAppSelector} from "../../hooks/reduxHooks";
import {RootState} from "../../store/store";

const AdminNavigation = () => {
    const dispatch = useDispatch();
    const authState = useAppSelector((state: RootState) => state.auth);

    return (
      <ContentContainer>
        <nav className={"flex w-full justify-between py-5"}>
          <div className={"flex gap-x-6"}>
            <NavigationButton
              text={"Аккаунты"}
              href={"/customers"}
              icon={<FaUsers size={20} />}
            />
            <NavigationButton
              text={"Линки"}
              href={"/customers-links"}
              icon={<RiLinksFill size={20} />}
            />
            {authState?.user?.role === "admin" ||
            authState?.user?.role === "superadmin" ? (
              <NavigationButton
                text={"Пользователи*"}
                href={"/users"}
                icon={<FaUsers size={20} />}
              />
            ) : null}
            <NavigationButton
              text={"Бан лист каналов"}
              href={"/channels-banlist"}
              icon={<FaBan size={20} />}
            />
          </div>
          <div className="flex items-center gap-x-4">
            {authState?.user && (
              <div className="flex flex-col items-end">
                <span className="text-sm font-medium text-white">
                  {authState?.user.nickname}
                </span>
                <span className="text-xs text-white">
                  {authState?.user.role}
                </span>
              </div>
            )}
            <NavigationButton
              text={"Выход"}
              href={"/admin/sign-in"}
              icon={<MdExitToApp size={20} />}
              cb={() => {
                dispatch(logout());
              }}
            />
          </div>
        </nav>
      </ContentContainer>
    );
};

export default AdminNavigation;