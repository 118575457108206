// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UrlVideo_thumbnailControls__5zio0 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: -moz-fit-content;
    height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/components/media-components/UrlVideo.module.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,wBAAmB;IAAnB,mBAAmB;AACvB","sourcesContent":[".thumbnailControls {\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    height: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnailControls": `UrlVideo_thumbnailControls__5zio0`
};
export default ___CSS_LOADER_EXPORT___;
