import { useCallback, useEffect, useState } from "react";
import { IMedia, MediaType } from "../../../common/types/media";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { ICustomer } from "../../../common/types/customer";
import { MediaQuery } from "../../../common/types/plagination";
import api from "../../../api/baseApi";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import BackArrowNavigate from "../../../components/buttons/BackArrowNavigate";
import { Audio } from "../../../components/media-components/Audio";
import CookieHandler from "./CookieHandler";
import classes from './commonPagesStyles.module.css';

const CustomerAudioPage = () => {
  const [audioArr, setAudioArr] = useState<IMedia[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLastPageReached, setIsLastPageReached] = useState(false);
  const customer = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;
  const id = customer.tgId;

  const [query, setQuery] = useState<MediaQuery>({
    page: 1,
    limit: 10,
    mediaType: MediaType.AUDIO,
  });

  const getCurrentPageCookieKey = () => `"currentPage_media_${id}_${query.mediaType}"`;

  const fetchAudio = useCallback(
    async (page: number, limit: number) => {
      if (isLastPageReached) {
        return;
      }
      setIsLoading(true);
      try {
        const url = `/customers/media/${id}?mediaType=${query.mediaType}&page=${page}&limit=${limit}`;
        const result = await api(url, {
          method: "GET",
          headers: {
            credentials: "include",
          },
        });
        
        const newAudios = result as IMedia[];
        if (newAudios.length < (query.limit)) {
          setIsLastPageReached(true);
        }

        setAudioArr((prevAudios) =>
          page === 1
            ? newAudios
            : [...prevAudios, ...newAudios]
        );
      } catch (error) {
        console.log("Error fetching images", error);
      } finally {
        setIsLoading(false);
      }
    },
    [id, query.limit]
  );

  const handleLoadMore = () => {
    if (!isLoading && !isLastPageReached) {
      setQuery((prev) => {
        const newPage = prev.page + 1;
        CookieHandler.setCookie(getCurrentPageCookieKey(), `${newPage}`, 1);
        return { ...prev, page: newPage };
      });
    }
  };

  useEffect(() => {
    const currentPageCookieValue = CookieHandler.getCookieInt(getCurrentPageCookieKey());
    if (currentPageCookieValue !== undefined && currentPageCookieValue > 1 && query.page < currentPageCookieValue) {
      fetchAudio(1, (currentPageCookieValue - 1) * query.limit)
        .then(async () => {
          await new Promise((resolve) => setTimeout(resolve, 500));
          setQuery((prev) => ({ ...prev, page: currentPageCookieValue }));
        })
    } else {
      fetchAudio(query.page, query.limit);
    }
  }, [fetchAudio, query.page]);

  if (isLoading && query.page === 1) {
    return (
      <ContentContainer>
        <CustomersInfoCard customPadding={"p-[30px]"}>
          <div>
            Идет загрузка аудиофайлов, это может занять некоторое время...
          </div>
        </CustomersInfoCard>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <CustomersInfoCard customPadding={"p-[30px]"}>
        <div className={"flex flex-col w-full min-h-[600px]"}>
          <div className={"w-full flex justify-end gap-x-3 py-3"}>
            <div className={"flex items-center gap-x-2"}>
              <BackArrowNavigate backUrl={`/customers/${id}/data/files`} />
            </div>
          </div>
          <div className={`${classes.scrollableContainer} flex flex-wrap gap-5 w-full h-full justify-start pl-16`}>
            {audioArr.map((audio: IMedia) => (
              <Audio media={audio} key={audio.id} />
            ))}
          </div>
          {!isLastPageReached && (
            <div className={classes.loadMoreContainer}>
              <button
                disabled={isLoading}
                onClick={handleLoadMore}
                className={classes.loadMoreButton}
              >
                {isLoading && <div>Загрузка...</div>}
                {!isLoading && <div>Загрузить ещё</div>}
              </button>
            </div>
          )}
        </div>
      </CustomersInfoCard>
    </ContentContainer>
  );
};

export default CustomerAudioPage;
