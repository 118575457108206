import React, {FC, ReactNode} from 'react';

interface ContentContainerProps {
    children: ReactNode;
}

const ContentContainer: FC<ContentContainerProps> = ({children}) => {
    return (
      <div
        className={
          "mx-auto p-4 flex justify-center pb-10 max-w-[1200px] overflow-y-auto"
        }
      >
        {children}
      </div>
    );
};

export default ContentContainer;