import React, { FC, useEffect, useState } from "react";
import { IMedia } from "../../common/types/media";
import PlayIcon from "../../assets/icons/PlayIcon";
import DownloadIcon from "../../assets/icons/DownloadIcon";

interface BufferVideoComponentProps {
  media: IMedia;
  className?: string;
  handleThumbnailClick?: (messageId: string) => void;
}

const UrlVideo: FC<BufferVideoComponentProps> = ({
  media,
  className,
  handleThumbnailClick,
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    if (media?.url) {
      setIsVideoPlaying(media.url.endsWith(".mp4"));
    }
  }, [media?.url]);

  const onThumbnailClick = () => {
    setIsVideoPlaying(true);
    if (handleThumbnailClick) {
      handleThumbnailClick(media.messageId);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = media.downloadLink ?? '';
    link.download = media?.downloadLink?.split("/").pop() || "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="relative w-full h-full max-w-[250px] max-h-[250px] pb-2 rounded-md">
      {isVideoPlaying ? (
        <video
          key={media.url}
          controls
          className={`w-full h-full max-w-[250px] max-h-[250px] rounded-md ${className}`}
        >
          <source src={media.url as string} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          src={media.url}
          alt="Thumbnail"
          className={`w-full h-full rounded-md cursor-pointer ${className}`}
          onClick={onThumbnailClick}
        />
      )}

      {!isVideoPlaying && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div
            title="Download"
            className="cursor-pointer"
            onClick={handleDownload}
          >
            <DownloadIcon />
          </div>

          <div
            title="Play"
            className="flex items-center justify-center text-white opacity-75 cursor-pointer"
            onClick={onThumbnailClick}
          >
            <PlayIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default UrlVideo;
