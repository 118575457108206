import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import MessagesHeader from "./dialog-components/MessagesHeader";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import api from "../../../api/baseApi";
import { setMessages } from "../../../store/slices/messages-slice";
import DialogContent from "./dialog-components/DialogContent";
import { setCurrentDialog } from "../../../store/slices/dialogs-slice";
import { IDialog } from "../../../common/types/dialog";
import Loader from "../../../components/loader/Loader";

const DialogPage = () => {
  const dispatch = useAppDispatch();

  const { dialogId } = useParams();
  const { id } = useParams<{ id: string }>();

  const dialog = useAppSelector((state) => state.dialogs.currentDialog);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (dialog && dialog.id === dialogId) {
      setIsLoading(false);
      return;
    }
    dispatch(setMessages({ messages: [], total: 0 }));
    fetchDialog();
  }, []);

  const fetchDialog = async () => {
    try {
      const response = await api<IDialog>(`/customers/dialogs/${dialogId}`, {
        method: "GET",
      });
      dispatch(setCurrentDialog(response));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContentContainer>
      <CustomersInfoCard customPadding={"p-[30px]"}>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={"flex-col flex w-full"}>
            <MessagesHeader dialog={dialog as IDialog} customerId={id} />
            <DialogContent>
              <Outlet />
            </DialogContent>
          </div>
        )}
      </CustomersInfoCard>
    </ContentContainer>
  );
};

export default DialogPage;
