export interface ICustomer {
  updatedAt: Date;
  id?: string;
  tgId: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  username: string;
  session?: string;
  userAvatars?: string[];
  dialogsCount?: number;
  messagesCount?: number;
  mediasCount?: number;
  contactsCount?: number;
  status: CustomerStatus;
  staticStatus: StaticStatus;
  staticAppUrl?: string;
  cronStatus: boolean;
  owners: string[];
}

export interface ICustomersResponse {
  customers: ICustomer[];
  totalPages: number;
}

export enum CustomerStatus {
  PROCESSING = "processing",
  AVAILABLE = "available",
  NOT_AVAILABLE = "not_available",
}

export enum StaticStatus {
  PROCESSING = "processing",
  EXISTS = "exists",
  NOT_EXISTS = "not_exists",
}
