import React, {FC} from 'react';
import {CustomerLink} from "../../../../common/types/customer-link";
import LinkFormInput from "../add-link-dialog-window/LinkFormInput";
import * as yup from "yup";
import {useFormik} from "formik";

interface EditLinkFormProps {
    link: CustomerLink;
    updateLink: (link: CustomerLink) => void;
}

const EditLinkForm: FC<EditLinkFormProps> = ({link, updateLink}) => {
    const validationSchema = yup.object({
        name: yup.string().required('Поле имя обязательное'),
        phone: yup.string().required('Номер телефона обязателен').matches(/^\+\d{11,15}$/, 'Неверный формат номера'),
        redirectUrl: yup.string().required('Поле URL обязательное').url('Неверный формат ссылки')
    });


    const form = useFormik({
        initialValues: {
            name: link.name,
            phone: link.phone_number,
            redirectUrl: link.redirect_url
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: validationSchema,

        onSubmit: (values) => {
            updateLink({
                ...link,
                phone_number: values.phone,
                redirect_url: values.redirectUrl,
                name: values.name,
            })
        },
    });

    const handlePhoneInputChange = (event: any) => {

        let value = event.target.value;
        if (value.startsWith('++')) {
            value = value.slice(1);
        } else if (!value.startsWith('+')) {
            value = '+' + value;
        }
        form.setFieldValue('phone', value);
        form.validateField('phone');
    }

    const isFormValid = () => {
        return (
            !Object.keys(form.errors).length &&
            Object.values(form.values).every(value => value.trim() !== '')
        );
    };

    const handleChange = (field: string) => (e: any) => {
        form.handleChange(e);
        form.validateField(field);
    };

    const handleBlur = (field: string) => (e: any) => {
        form.handleBlur(e);
        form.validateField(field);
    }



    return (
        <div
            className={'w-full min-w-[400px] gap-y-[20px] px-[60px] pb-[30px] flex flex-col justify-center items-center'}>
            <h3 className={'text-2xl font-bold self-start pb-4'}>
                Редактирование линки
            </h3>
            <label className={'w-full flex gap-y-1 flex-col items-start justify-center'}>
                <span className={'font-light text-base'}>Имя</span>
                <LinkFormInput
                    placeholder={'Имя'}
                    isValid={!(!!form.errors.name)}
                    value={form.values.name}
                    onBlur={handleBlur('name')}
                    onChange={handleChange('name')}
                    name={'name'}
                />
                <p className={'h-[20px] font-light text-red-600 mt-1.5'}>{form.errors.name && form.errors.name}</p>
            </label>
            <label className={'w-full flex gap-y-1 flex-col items-start justify-center'}>
                <span className={'font-light text-base py-1'}>Номер телефона</span>
                <LinkFormInput
                    placeholder={'Номер телефона'}
                    value={form.values.phone}
                    onChange={handlePhoneInputChange}
                    onBlur={handleBlur('phone')}
                    isValid={!(form.touched.phone && form.errors.phone)}
                    name={'phone'}
                />
                <p className={'h-[20px] font-light text-red-600 mt-1.5'}>{form.errors.phone && form.errors.phone}</p>
            </label>
            <label className={'w-full flex gap-y-1 flex-col items-start justify-center'}>
                <span className={'font-light text-base py-1'}>Ссылка для переадресации</span>
                <LinkFormInput
                    placeholder={'URL'}
                    value={form.values.redirectUrl}
                    onChange={handleChange('redirectUrl')}
                    onBlur={handleBlur('redirectUrl')}
                    onPaste={(e) => {
                        e.preventDefault();
                        form.setFieldValue('redirectUrl', e.clipboardData.getData('Text'));
                        form.validateField('redirectUrl');
                    }}
                    isValid={!(form.touched.redirectUrl && form.errors.redirectUrl)}
                    name={'redirectUrl'}
                />
                <p className={'h-[20px] font-light text-red-600 mt-1.5'}>{form.errors.redirectUrl && form.errors.redirectUrl}</p>

            </label>

            <button
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation()
                    form.handleSubmit()
                }
                }
                type={'submit'}
                className={`mt-[20px]  px-[80px] py-[10px] rounded-md transition-colors  ${!isFormValid() ? 'bg-gray-300 cursor-default text-gray-700' : 'hover:bg-blue-600 bg-blue-500 cursor-pointer'}`}>
                Сохранить
            </button>
        </div>
    );
};

export default EditLinkForm;