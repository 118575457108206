import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../api/baseApi";
import { IMedia, MediaType } from "../../../../common/types/media";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { setSelectedImage } from "../../../../store/slices/selectedImage-slice";
import { MediaQuery } from "../../../../common/types/plagination";
import { IDialog } from "../../../../common/types/dialog";
import Image from "../../../../components/media-components/Image";
import { ICustomer } from "../../../../common/types/customer";

const DialogImages = () => {
  const dialog = useAppSelector(
    (state) => state.dialogs.currentDialog
  ) as IDialog;
  const dialogId = dialog.tgId;
  const {id} = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<MediaQuery>({
    page: 1,
    limit: 10,
    mediaType: MediaType.PHOTO,
    customerId: id,
  });

  const [images, setImages] = useState<IMedia[]>([]);
  const dispatch = useAppDispatch();

  const fetchImages = async (page: number = query.page) => {
    try {
      const url = `/customers/dialog-media/${dialogId}?mediaType=${query.mediaType}&page=${page}&limit=${query.limit}&customerId=${query.customerId}`;

      const result = await api(url, {
        method: "GET",
        headers: {
          credentials: "include",
        },
      });


      setImages((prevImages) =>
        page === 1
          ? (result as IMedia[])
          : [...prevImages, ...(result as IMedia[])]
      );
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching images", error);
    }
  };

  const checkScroll = async () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight) {
      console.log("fetching more images");
      setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
      await fetchImages(query.page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [query.page]);

  useEffect(() => {
    fetchImages(1); // Fetch initial images
  }, []);

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  if (images.length === 0 && !isLoading) {
    return <div>Изображения отсутствуют.</div>;
  }

  return (
    <div
      className={
        "flex flex-wrap gap-3 w-full h-full min-h-screen  justify-start pl-20"
      }
    >
      {images.map((image: IMedia) => (
        <Image media={image} key={image.id} />
      ))}
    </div>
  );
};

export default DialogImages;
