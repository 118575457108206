import React, { useEffect, useState } from "react";
import { IContact } from "../../../common/types/telegram-user";
import api from "../../../api/baseApi";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { ICustomer } from "../../../common/types/customer";
import ContactItem from "./ContactItem";
import RefreshContactButton from "../../../components/buttons/RefreshContactButton";

const ContactsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const customer = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;

  const { tgId } = customer;

  const fetchContacts = async () => {
    setIsLoading(true);
    const response = await api(`/customers/${tgId}/contacts`);
    setContacts(response as IContact[]);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchContacts();
  }, [tgId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoading && contacts.length === 0) {
    return <div>No contacts found</div>;
  }

  return (
    <div className="w-full flex flex-col gap-y-4 min-w-[800px]">
      <RefreshContactButton
        customerId={tgId}
        contacts={contacts}
        onRefresh={fetchContacts}
      />
      <div className="flex flex-col gap-y-2">
        {contacts.map((contact) => (
          <ContactItem contact={contact} customerId={tgId} key={contact.tgId} />
        ))}
      </div>
    </div>
  );
};

export default ContactsPage;
