import Router from "./router/Router";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="flex w-full h-full min-h-screen justify-center items-center bg-[#fff]">
      <BrowserRouter
        future={{ v7_relativeSplatPath: true, v7_startTransition: true }}
      >
        <Router />
      </BrowserRouter>
    </div>
  );
}

export default App;
