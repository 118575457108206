import React, { FC, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../../api/baseApi";
import { IUser } from "../../../../common/types/user";
import AddUserInput from "./AddUserInput";
import { toast } from "react-toastify";
import {useAppDispatch, useAppSelector} from "../../../../hooks/reduxHooks";
import { fetchUsers } from "../../../../store/slices/user-slice";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { IRole } from "../../../../common/types/role";
import { FaSpinner } from "react-icons/fa";

interface AddUserFormProps {
  roles: IRole[];
  hideAndUpdate: (values: IUser) => void;
  searchValue: string;
}

export interface AddUserFormValues {
  nickname: string;
  password: string;
  role: string;
}

const AddUserForm: FC<AddUserFormProps> = ({ hideAndUpdate, roles, searchValue }) => {
  const [showPassword, setShowPassword] = useState(false);
  const pagination = useAppSelector((state) => state.user.pagination);

  const dispatch = useAppDispatch();

  const validationSchema = yup.object({
    nickname: yup
      .string()
      .required("Поле никнейм обязательное")
      .matches(
        /^.{2,}$/,
        "Неверный формат никнейма (никнейм должен содержать 2 и более символов)"
      ),
    password: yup
      .string()
      .required("Пароль обязателен")
      .matches(
        /^.{8,}$/,
        "Неверный формат пароля(пароль должен содержать 8 и более символов)"
      ),
    role: yup.string().required("Роль обязательна"),
  });

  const form = useFormik({
    initialValues: {
      nickname: "",
      password: "",
      role: "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        const result = await api<IUser>("users", {
          data: {
            nickname: values.nickname,
            password: values.password,
            roleId: values.role,
          },
          method: "POST",
          headers: {
            credentials: "include",
          },
        });
        hideAndUpdate(result);
        toast.success("Пользователь успешно создан");
        dispatch(fetchUsers({page: Number(pagination.page), take: Number(pagination.take), name: searchValue}));
      } catch (error) {
        console.error(error);
        toast.error("Ошибка при создании пользователя");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleChange = (field: string) => (e: any) => {
    form.handleChange(e);
    form.validateField(field);
  };

  const handleBlur = (field: string) => (e: any) => {
    form.handleBlur(e);
    form.validateField(field);
  };

  return (
    <form
      className={
        "w-full min-w-[400px] gap-y-[20px] px-[60px] pb-[30px] flex flex-col justify-center items-center"
      }
      onSubmit={form.handleSubmit}
    >
      <h3 className={"text-2xl font-bold self-start pb-4"}>
        Добавление пользователя
      </h3>
      <label
        className={"w-full flex gap-y-1 flex-col items-start justify-center"}
      >
        <span className={"font-light text-base"}>Nickname</span>
        <AddUserInput
          placeholder={"Nickname"}
          isValid={!(form.touched.nickname && form.errors.nickname)}
          value={form.values.nickname}
          onBlur={handleBlur("nickname")}
          onChange={handleChange("nickname")}
          name={"nickname"}
          id={"nickname"}
        />
        <p className={"h-[20px] font-light text-red-600 mt-1.5"}>
          {form.touched.nickname && form.errors.nickname}
        </p>
      </label>
      <label
        className={"w-full flex gap-y-1 flex-col items-start justify-center"}
      >
        <span className={"font-light text-base"}>Password</span>
        <div className="relative w-full">
          <AddUserInput
            placeholder="********"
            isValid={!(form.touched.password && form.errors.password)}
            value={form.values.password}
            onBlur={handleBlur("password")}
            onChange={handleChange("password")}
            name={"password"}
            type={showPassword ? "text" : "password"}
            id={"password"}
          />
          <button
            onClick={() => setShowPassword(!showPassword)}
            type="button"
            className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md outline-none focus:outline-none focus:text-blue-600"
          >
            {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
          </button>
        </div>
        <p className={"h-[20px] font-light text-red-600 mt-1.5"}>
          {form.touched.password && form.errors.password}
        </p>
      </label>
      <label
          className={"w-full flex gap-y-1 flex-col items-start justify-center"}
      >
        <span className={"font-light text-base"}>Role</span>
        <select
            name="role"
            onChange={handleChange("role")}
            onBlur={handleBlur("role")}
            value={form.values.role}
            className={
              "bg-[#252627] p-1 rounded-md mt-1 w-full cursor-pointer outline-none focus:outline-none"
            }
        >
          <option value="" label="Выберите роль"/>
          {roles.map((role) => (
              <option key={role._id} value={role._id}>
                {role.name.toUpperCase()}
              </option>
          ))}
        </select>
        <p className={"h-[20px] font-light text-red-600 mt-1.5"}>
          {form.touched.role && form.errors.role}
        </p>
      </label>
      <button
          type="submit"
          disabled={!form.isValid || form.isSubmitting}
        className={
          "mt-[20px] px-[80px] py-[10px] rounded-md transition-colors hover:bg-blue-600 bg-blue-500 cursor-pointer disabled:bg-gray-400 flex items-center justify-center"
        }
      >
        {
          form.isSubmitting ? (
          <FaSpinner className="animate-spin mr-2" />
        ) : (
          "Добавить пользователя"
        )}
      </button>
    </form>
  );
};

export default AddUserForm;
