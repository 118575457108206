import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from "../../api/baseApi";
import { IBanlistChannel } from "../../common/types/banlistChannel";

export interface BanlistChannelState {
  channels: IBanlistChannel[];
  currentChannel?: IBanlistChannel | null;
  loading: boolean;
  error: string | null;
}

const initialState: BanlistChannelState = {
  channels: [],
  currentChannel: null,
  loading: false,
  error: null,
};

export const fetchBanlistChannels = createAsyncThunk(
  "banlist_channels",
  async (_, { rejectWithValue }) => {
    try {
      const result: IBanlistChannel[] = await api(`/customers/banned-dialogs`, {
        headers: {
          credentials: "include",
        },
      });

      return result;
    } catch (error: any) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

export const banlistChannelSlice = createSlice({
  name: "banlistChannel",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanlistChannels.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBanlistChannels.fulfilled,
        (state, action: PayloadAction<IBanlistChannel[]>) => {
          state.channels = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchBanlistChannels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const BanlistChannelsReducer = banlistChannelSlice.reducer;
