import CustomerNavButton from "../buttons/CustomerNavButton";
import { useAppSelector } from "../../hooks/reduxHooks";
import { ICustomer } from "../../common/types/customer";
import BurgerMenuIcon from "../../assets/icons/BurgerMenuIcon";
import AttachIcon from "../../assets/icons/AttachIcon";
import ContactIcon from "../../assets/icons/ContactIcon";
import ChatIcon from "../../assets/icons/ChatIcon";

const CustomerInfoNavigation = () => {
  const customer = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;
  const { tgId } = customer;
  return (
    <nav className={"flex flex-col p-[40px] gap-y-2 font-bold font-lg"}>
      <CustomerNavButton
        icon={<BurgerMenuIcon />}
        text={"Главная"}
        href={`/customers/${tgId}`}
      />
      <CustomerNavButton
        icon={<ChatIcon />}
        text={"Диалоги"}
        href={`/customers/${tgId}/data/all-dialogs`}
      />
      <CustomerNavButton
        icon={<AttachIcon />}
        text={"Вложения"}
        href={`/customers/${tgId}/data/files`}
      />
      <CustomerNavButton
        icon={<ContactIcon />}
        text={"Контакты"}
        href={`/customers/${tgId}/data/contacts`}
      />
    </nav>
  );
};

export default CustomerInfoNavigation;
