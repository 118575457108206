import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

interface IDialogNavButtonProps {
  href: string;
  icon: React.ReactNode;
}

const DialogNavButton: FC<IDialogNavButtonProps> = ({ href, icon }) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(href === location.pathname);

  useEffect(() => {
    setIsActive(href === location.pathname);
  }, [location.pathname]);

  return (
    <Link
      to={href}
      className={`p-1.5 rounded-md flex items-center justify-center transition-colors ${
        isActive
          ? "cursor-default bg-[#3d3e3f] text-gray-400"
          : "cursor-pointer hover:bg-[#3d3e3f]"
      }`}
    >
      {icon}
    </Link>
  );
};

export default DialogNavButton;
