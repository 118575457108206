import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { LoadingProvider } from "./providers/LoadingContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

window.onerror = function (message, source, lineno, colno, error) {
  if (message === "Script error.") {
    return true;
  }
  console.error("Error:", message, "at", source, ":", lineno, ":", colno);
  return true;
};

window.addEventListener("error", function (e) {
  e.preventDefault();
  console.error(
    "Error:",
    e.message,
    "at",
    e.filename,
    ":",
    e.lineno,
    ":",
    e.colno
  );
});

window.addEventListener("unhandledrejection", function (event) {
  console.error("Unhandled rejection:", event.reason);
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoadingProvider>
          <App />
          <ToastContainer />
        </LoadingProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
