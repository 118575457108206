import React, {useEffect, useState} from "react";
import api from "../../../api/baseApi";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import {CiSquarePlus} from "react-icons/ci";
import AddUserDialogWindow from "./add-user-dialog-window/AddUserDialogWindow";
import UsersList from "./UsersList";
import {IUser} from "../../../common/types/user";
import RemoveUserDialogWindow from "./remove-user-dialog-window/RemoveUserDialogWindow";
import EditUserDialogWindow from "./update-user-dialog-window/EditUserDialogWindow";
import Bugsnag from "@bugsnag/js";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import {fetchUsers} from "../../../store/slices/user-slice";
import Loader from "../../../components/loader/Loader";
import {toast} from "react-toastify";
import Pagination from "../../../components/Pagination";
import Search from "../../../components/Search";
import {useURLParams} from "../../../common/util/saveSearchParams";

export default function UsersPage() {
  const [addingNewUser, setAddingNewUser] = useState<boolean>(false);
  const [editingUser, setEditingUser] = useState<IUser | null>(null);
  const [removedUser, setRemovedUser] = useState<IUser | null>(null);
  const [quantityOnPage, setQuantityOnPage] = useState(10);

  const dispatch = useAppDispatch();
  const usersData = useAppSelector((state) => state.user.users);
  const pagination = useAppSelector((state) => state.user.pagination);
  const isLoading = useAppSelector((state) => state.user.loading);

  const { page, search, setPage, setSearch } = useURLParams();

  const hideAndUpdate = () => {
    setAddingNewUser(false);
  };

  const handleSearch = (value: string) => {
    setSearch(value)
  }

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const updateUser = async (user: IUser) => {
    try {
      await api(`users/${user.id}`, {
        data: {
          nickname: user.nickname,
          password: user.password,
          roleId: user.role.id,
        },
        method: "PATCH",
        headers: {
          credentials: "include",
        },
      });
      setEditingUser(null);
      toast.success("Пользователь успешно обновлен");
      dispatch(fetchUsers({page: page, take: quantityOnPage, name: search}));
    } catch (error: any) {
      console.log(error);
      toast.error("Ошибка при обновлении пользователя");
    }
  };

  const deleteUser = async () => {
    try {
      await api(`users/${removedUser?.id}`, {
        method: "DELETE",
        headers: {
          credentials: "include",
        },
      });
      setRemovedUser(null);
      toast.success("Пользователь успешно удалён");
      dispatch(fetchUsers({page: page, take: quantityOnPage, name: search}));
    } catch (error) {
      console.log(error);
      toast.error("Ошибка при удалении пользователя");
    }
  };

  useEffect(() => {
    if (addingNewUser || editingUser || removedUser) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [addingNewUser, editingUser, removedUser]);

  useEffect(() => {
    dispatch(fetchUsers({page: page, take: quantityOnPage, name: search}));
    Bugsnag.notify(new Error("Test prod error"));
  }, [page, search]);

  useEffect(() => {
    if (page > pagination.pageCount && (page > 0) && pagination.pageCount !== 0)  {
      setPage(1)
    }
  }, [pagination.pageCount]);

  return (
    <>
      {addingNewUser && (
        <AddUserDialogWindow
          hideCb={() => setAddingNewUser(false)}
          hideAndUpdate={hideAndUpdate}
          searchValue={search}
        />
      )}
      {removedUser && (
        <RemoveUserDialogWindow
          hide={() => {
            setRemovedUser(null);
          }}
          removeUser={() => deleteUser()}
        />
      )}
      {editingUser && (
        <EditUserDialogWindow
          hide={() => setEditingUser(null)}
          updateUser={(user) => updateUser(user)}
          user={editingUser}
        />
      )}
      <ContentContainer>
        <CustomersInfoCard customPadding={"p-[30px]"}>
          <div className={"flex flex-col gap-5 w-full min-h-[75vh]"}>
            <div className={"flex justify-between"}>
              <button
                  onClick={() => setAddingNewUser((prevState) => !prevState)}
                  className={
                    "bg-[#141515] border border-[#252627] w-[350px]  text-[#d7d7d7] transition-colors items-center rounded-md hover:bg-[#3d3e3f] gap-x-4  px-8 p-3 flex justify-between"
                  }
              >
                Добавить нового пользователя
                <CiSquarePlus size={25}/>
              </button>
              <Search textPlaceholder={'Поиск пользователя...'} onSearch={(value) => handleSearch(value)} startValue={search}/>
            </div>

            <div className={"min-h-[57vh]"}>
              {isLoading ? (
                  <Loader/>
              ) : (
                  <div className={"flex gap-2 flex-col bg-[#141515] min-h-[57vh]"}>
                    <div className={"flex"}>
                      <p
                          className={"w-[40px] px-2 py-1  border border-[#515151]"}
                      ></p>
                      <p className={"flex-1 border px-2 py-1 border-[#515151]"}>
                    имя пользователя
                  </p>
                  <p className={"flex-1 border px-2 py-1 border-[#515151]"}>
                    роль пользователя
                  </p>
                  <p
                    className={"w-[40px] px-2 py-1  border border-[#515151]"}
                  ></p>
                  <p
                    className={"w-[40px] px-2 py-1  border border-[#515151]"}
                  ></p>
                </div>
                <UsersList
                  users={usersData}
                  deleteUser={(user) => {
                    setRemovedUser(user);
                  }}
                  updateUser={(user) => {
                    setEditingUser(user);
                  }}
                />
              </div>
            )}
            </div>
            <Pagination onPageChange={(page) => onPageChange(page)} currentPage={page} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} pageCount={pagination.pageCount}/>
          </div>
        </CustomersInfoCard>
      </ContentContainer>
    </>
  );
}
