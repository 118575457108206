import React, { FC, useEffect, useState } from "react";
import { IMedia } from "../../common/types/media";
import { FaRegPlayCircle } from "react-icons/fa";

interface BufferVideoComponentProps {
  media: IMedia;
  className?: string;
  handleThumbnailClick?: (messageId: string) => void;
}

const UrlVideo: FC<BufferVideoComponentProps> = ({
  media,
  className,
  handleThumbnailClick,
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    if (media?.url) {
      setIsVideoPlaying(media.url.endsWith(".mp4"));
    }
  }, [media?.url]);

  const onThumbnailClick = () => {
    setIsVideoPlaying(true);
    if (handleThumbnailClick) {
      handleThumbnailClick(media.messageId);
    }
  };

  return (
    <div className="relative w-full h-full max-w-[250px] max-h-[250px] pb-2 rounded-md">
      {isVideoPlaying ? (
        <video
          key={media.url}
          controls
          className={`w-full h-full max-w-[250px] max-h-[250px] rounded-md ${className}`}
        >
          <source src={media.url as string} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          src={media.url}
          alt="Thumbnail"
          className={`w-full h-full rounded-md cursor-pointer ${className}`}
          onClick={onThumbnailClick}
        />
      )}

      {!isVideoPlaying && (
        <div className="absolute inset-0 flex items-center justify-center">
          <FaRegPlayCircle
            className="w-16 h-16 text-white opacity-75 cursor-pointer"
            onClick={onThumbnailClick}
          />
        </div>
      )}
    </div>
  );
};

export default UrlVideo;
