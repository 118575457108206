import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import DialogListItem from "./DialogListItem";
import { useURLParams } from "../../../common/util/saveSearchParams";
import Pagination from "../../../components/Pagination";
import Search from "../../../components/Search";
import { fetchDialogs } from "../../../store/slices/dialogs-slice";
import Loader from "../../../components/loader/Loader";
import { IDialog } from "../../../common/types/dialog";
import api from "../../../api/baseApi";

export default function AllDialogsPage() {
  const dispatch = useAppDispatch();
  const customersData = useAppSelector(
    (state) => state.customer.currentCustomer
  );
  const dialogs = useAppSelector((state) => state.dialogs.dialogs);
  const pagination = useAppSelector((state) => state.dialogs.pagination);
  const [quantityOnPage, setQuantityOnPage] = useState(10);
  const [queryParams, setQueryParams] = useState({
    customerId: customersData?.tgId,
  });
  const isLoading = useAppSelector((state) => state.dialogs.loading);

  const { page, search, setPage, setSearch } = useURLParams();

  const handleSearch = (value: string) => {
    if (search !== value) {
      setSearch(value);
      setPage(1);
    } else {
      setSearch(value);
    }
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const handleBanChannel = async (dialog: IDialog) => {
    try {
      await api("/customers/ban-dialog", {
        method: "PATCH",
        data: {
          tgId: dialog.tgId,
          isBanned: !dialog.isBanned,
        },
        headers: {
          credentials: "include",
        },
      });
      dispatch(
        fetchDialogs({
          customerId: queryParams.customerId,
          page: page,
          quantityOnPage: quantityOnPage,
          search: search,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(
      fetchDialogs({
        customerId: queryParams.customerId,
        page: page,
        quantityOnPage: quantityOnPage,
        search: search,
      })
    );
  }, [page, search]);

  return (
    <div className={"flex flex-col gap-y-2 w-full min-w-[800px]"}>
      <Search
        textPlaceholder={"Поиск диалога..."}
        onSearch={(value) => handleSearch(value)}
        startValue={search}
      />
      {isLoading ? (
        <Loader />
      ) : (
        dialogs.map((dialog) => (
          <DialogListItem
            dialog={dialog}
            key={dialog.tgId}
            handleBanChannel={handleBanChannel}
          />
        ))
      )}
      <Pagination
        onPageChange={(page) => onPageChange(page)}
        currentPage={page}
        hasPreviousPage={pagination.hasPreviousPage}
        hasNextPage={pagination.hasNextPage}
        pageCount={pagination.pageCount}
      />
    </div>
  );
}
