import { FC } from "react";
import LinkItemButton from "../customer-links/links-table/LinkItemButton";
import { IBanlistChannel } from "../../../common/types/banlistChannel";
import TrashIcon from "../../../assets/icons/TrashIcon";

interface BanlistChannelListItemProps {
  banlistChannel: IBanlistChannel;
  index: number;
  deleteBanlistChannel: (banlistChannel: IBanlistChannel) => void;
}

const BanlistChannelListItem: FC<BanlistChannelListItemProps> = ({
  banlistChannel,
  index,
  deleteBanlistChannel,
}) => {
  return (
    <div className={"flex hover:bg-[#1E1E1E]"}>
      <p
        className={
          "w-[40px] flex justify-center items-center border border-[#252627]"
        }
      >
        {index}
      </p>
      <p
        className={
          "flex-1 flex justify-start items-center border px-2 py-1 border-[#252627]"
        }
      >
        {banlistChannel.tgId}
      </p>
      <p
        className={
          "flex-1 flex justify-start items-center border px-2 py-1 border-[#252627]"
        }
      >
        {banlistChannel.name}
      </p>
      {/* <LinkItemButton
        icon={<PencilIcon />}
        cb={() => updateBanlistChannel(banlistChannel)}
      /> */}
      <LinkItemButton
        icon={<TrashIcon />}
        cb={() => deleteBanlistChannel(banlistChannel)}
      />
    </div>
  );
};

export default BanlistChannelListItem;
