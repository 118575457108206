import { FC, useEffect, useState } from "react";
import { CustomerStatus, ICustomer } from "../../../common/types/customer";
import { Link } from "react-router-dom";
import Avatar from "../../../components/media-components/Avatar";
import Counter from "../../../components/Counter";
import { copyToClipboard, isValidImageUrl } from "../../../common/util";
import { useAppSelector } from "../../../hooks/reduxHooks";
import ReparseButton from "../../../components/buttons/ReparsingCustomerButton";
import api from "../../../api/baseApi";
import GearIcon from "../../../assets/icons/GearIcon";
import BookIcon from "../../../assets/icons/BookIcon";
import AttachIcon from "../../../assets/icons/AttachIcon";
import ChatIcon from "../../../assets/icons/ChatIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";
import KeyIcon from "../../../assets/icons/KeyIcon";

interface CustomerListItemProps {
  customer: ICustomer;
  onDelete: (tgId: string) => void;
  onChangeSettings: (tgId: string) => void;
}

const CustomerListItem: FC<CustomerListItemProps> = ({
  customer,
  onDelete,
  onChangeSettings,
}) => {
  const customers = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;

  const [borderClass, setBorderClass] = useState("");
  const [loading, setLoading] = useState(false);

  const getStatusColor = (status: CustomerStatus) => {
    switch (status) {
      case CustomerStatus.AVAILABLE:
        return "bg-green-500";
      case CustomerStatus.PROCESSING:
        return "bg-yellow-500";
      default:
        return "bg-red-500";
    }
  };

  const checkStatus = async (customerId: string) => {
    setLoading(true);
    try {
      const response = await api(`/parser/sessionStatus/${customerId}`, {
        method: "GET",
      });
      const color = getStatusColor(response as CustomerStatus);
      setBorderClass(color);
    } catch (error) {
      console.log("check session", error);
    } finally {
      setLoading(false);
    }
  };

  const userAvatar = customer.userAvatars?.find(isValidImageUrl) || "";

  useEffect(() => {
    const color = getStatusColor(customer.status);
    setBorderClass(color);
  }, [customer.status]);

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) {
      return "Дата неизвестна";
    }
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <div
      className="relative flex justify-between items-center rounded-md w-full cursor-pointer text py-[10px] px-[20px] transition-colors hover:bg-[#3d3e3f] bg-[#272727] text-[#d7d7d7]"
      title={`Последнее обновление: ${formatDate(customers?.updatedAt)}`}
    >
      <Link
        to={`/customers/${customer.tgId}`}
        className="absolute inset-0 z-10"
      />
      <div className="flex items-center gap-x-4">
        <div className={`rounded-full w-[48px] h-[48px]`}>
          <Avatar url={userAvatar} />
        </div>
        <div className="flex flex-col z-50">
          <div className="flex gap-x-2 font-semibold text-sm items-center">
            <p>@{customer.username}</p>
            <p className="text-[#161616]">|</p>
            <p>
              <span>
                {customer?.firstName ?? ""} {customer?.lastName || ""}
              </span>
            </p>
          </div>
          <div className="flex items-center gap-x-2">
            <p
              title="Click to copy"
              onClick={() => copyToClipboard(customer.tgId, "Customer tgId")}
            >
              tgId: {customer.tgId}
            </p>{" "}
            {Boolean(customer?.phone) ? (
              <p
                title="Click to copy"
                onClick={() =>
                  customer.phone &&
                  copyToClipboard(customer.phone, "Customer phone number")
                }
              >
                tel: {customer?.phone}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex gap-x-2 items-center">
        <div className="flex items-center gap-x-2">
          <p className="text-[#d7d7d7]">Status</p>
          <div className={`w-2 h-2 rounded-full ${borderClass}`}></div>
        </div>
        <Counter count={customer.dialogsCount as number} icon={<ChatIcon />} />
        <Counter count={customer.mediasCount as number} icon={<AttachIcon />} />
        <Counter
          count={(customer.contactsCount as number) || 0}
          icon={<BookIcon />}
        />
        <button
          title="Настройки доступа"
          onClick={() => onChangeSettings(customer.tgId)}
          className="w-9 h-9  flex items-center gap-x-2 text-white p-2 transition-colors bg-black rounded-lg z-30 relative"
        >
          <GearIcon />
        </button>
        <ReparseButton customerId={customer.tgId} />
        <button
          onClick={() => checkStatus(customer.tgId)}
          title="Статус сессии"
          className="w-9 h-9 flex gap-x-2 items-center justify-center text-white p-2 transition-colors rounded-lg bg-[#1e3a8a] hover:bg-[#1d4ed8] border border-blue-700 z-30 relative"
        >
          {loading ? <div className="loader !w-4 !h-4"></div> : <KeyIcon />}
        </button>

        <button
          onClick={() => onDelete(customer.tgId)}
          className="w-9 h-9 flex items-center gap-x-2 text-white p-2 transition-colors rounded-lg hover:bg-red-700 bg-red-600 z-30 relative"
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};

export default CustomerListItem;
