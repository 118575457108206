import React, {useEffect, useState} from 'react';
import CustomersInfoCard from "../cards/CustomersInfoCard";
import ContentContainer from "./ContentContainer";
import CustomerInfoHeader from "./CustomerInfoHeader";
import CustomerInfoNavigation from "./CustomerInfoNavigation";
import {Outlet, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import api from "../../api/baseApi";
import {ICustomer} from "../../common/types/customer";
import {setCurrentCustomer} from "../../store/slices/customer-slice";
import Loader from "../loader/Loader";

const CustomerInfoLayout = () => {
    const {id} = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const currentCustomer = useAppSelector((state) => {
        return state.customer.currentCustomer;
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!currentCustomer) {
            fetchCustomer();
        } else {
            setIsLoading(false)
        }
    }, [])

    const fetchCustomer = async () => {
        try {
            const response = await api<ICustomer>(`/customers/${id}`, {
                method: 'get',
            });
            dispatch(setCurrentCustomer(response));
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <ContentContainer>
            <CustomersInfoCard customPadding={'p-[30px]'}>
                {
                    isLoading ? (
                        <Loader/>
                    ) : (
                        <div className={'flex flex-col'}>
                            <CustomerInfoHeader/>
                            <div className={'flex flex-col bg-[#191919] border-2 border-[#252627] rounded-md'}>
                                <div className={'flex'}>
                                    <CustomerInfoNavigation/>
                                    <div className={'p-[30px] bg-[#1c1d1d] border rounded-md border-[#252627]'}>
                                        <Outlet/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </CustomersInfoCard>
        </ContentContainer>
    );
};

export default CustomerInfoLayout;