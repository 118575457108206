import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import ChatBubbleSolid from "../../../assets/icons/ChatBubbleSolid";
import Button from "../../../components/buttons/Button";

interface OpenContactDialogButtonProps {
  customerId: string;
  dialogId: string;
}

const OpenContactDialogButton: FC<OpenContactDialogButtonProps> = ({
  customerId,
  dialogId,
}) => {
  const navigate = useNavigate();
  const isLinkDisabled = !dialogId;
  const handleLinkClick = (event: React.MouseEvent) => {
    console.log("sdfs", dialogId);

    if (isLinkDisabled) {
      event.preventDefault();
      event.stopPropagation();
    }
    navigate(`/customers/${customerId}/data/dialogs/${dialogId}`);
  };

  return (
    <Button
      onClick={(event) => handleLinkClick(event)}
      className={
        "cursor-pointer flex justify-center items-center gap-x-2 px-4 hover:text-green-500  "
      }
    >
      Перейти к диалогу <ChatBubbleSolid />
    </Button>
  );
};

export default OpenContactDialogButton;
