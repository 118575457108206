import { FC } from "react";
import DocumentIcon from "../../assets/icons/DocumentIcon";

interface DownloadDocumentProps {
  base64: string;
  mimeType: string;
}

const DownloadDocument: FC<DownloadDocumentProps> = ({ base64, mimeType }) => {
  let blobUrl: any;
  let name: any;
  try {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    blobUrl = URL.createObjectURL(blob);

    name = mimeType.split("/")[1];
  } catch (error) {
    console.log("Failed to decode Base64 string in DownloadDocument:", error);
  }

  return (
    <a
      href={blobUrl}
      download={`${mimeType}`}
      className={
        "flex w-[150px] overflow-hidden gap-y-3 select-none cursor-pointer  h-[130px] text-white justify-start items-center flex-col p-3 rounded-md transition-colors hover:bg-[#3d3e3f]"
      }
    >
      <DocumentIcon />
      <span className={"line-clamp-1"}>{name}</span>
    </a>
  );
};

export default DownloadDocument;
