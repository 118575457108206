import { useCallback, useEffect, useState } from "react";
import { IMedia, MediaType } from "../../../common/types/media";
import { MediaQuery } from "../../../common/types/plagination";
import api from "../../../api/baseApi";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import BackArrowNavigate from "../../../components/buttons/BackArrowNavigate";
import { Document } from "../../../components/media-components/Document";
import { useParams } from "react-router-dom";
import CookieHandler from "./CookieHandler";
import classes from './commonPagesStyles.module.css';

const CustomerDocumentsPage = () => {
  const [documents, setDocuments] = useState<IMedia[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLastPageReached, setIsLastPageReached] = useState(false);
  const { id } = useParams<{ id: string }>();

  const [query, setQuery] = useState<MediaQuery>({
    page: 1,
    limit: 10,
    mediaType: MediaType.DOCUMENT,
  });

  const getCurrentPageCookieKey = () => `"currentPage_media_${id}_${query.mediaType}"`;

  const fetchDocuments = useCallback(
    async (page: number, limit: number) => {
      if (isLastPageReached) {
        return;
      }
      setIsLoading(true);
      try {
        const url = `/customers/media/${id}?mediaType=${query.mediaType}&page=${page}&limit=${limit}`;
        const result = await api(url, {
          method: "GET",
          headers: {
            credentials: "include",
          },
        });
        
        const newDocuments = result as IMedia[];
        if (newDocuments.length < (query.limit)) {
          setIsLastPageReached(true);
        }

        setDocuments((prevDocuments) =>
          page === 1
            ? newDocuments
            : [...prevDocuments, ...newDocuments]
        );
      } catch (error) {
        console.log("Error fetching images", error);
      } finally {
        setIsLoading(false);
      }
    },
    [id, query.limit]
  );

  const handleLoadMore = () => {
    if (!isLoading && !isLastPageReached) {
      setQuery((prev) => {
        const newPage = prev.page + 1;
        CookieHandler.setCookie(getCurrentPageCookieKey(), `${newPage}`, 1);
        return { ...prev, page: newPage };
      });
    }
  };

  useEffect(() => {
    const currentPageCookieValue = CookieHandler.getCookieInt(getCurrentPageCookieKey());
    if (currentPageCookieValue !== undefined && currentPageCookieValue > 1 && query.page < currentPageCookieValue) {
      fetchDocuments(1, (currentPageCookieValue - 1) * query.limit)
        .then(async () => {
          await new Promise((resolve) => setTimeout(resolve, 500));
          setQuery((prev) => ({ ...prev, page: currentPageCookieValue }));
        })
    } else {
      fetchDocuments(query.page, query.limit);
    }
  }, [fetchDocuments, query.page]);

  if (isLoading && query.page === 1) {
    return (
      <ContentContainer>
        <CustomersInfoCard customPadding={"p-[30px]"}>
          <div>
            Идет загрузка документов, это может занять некоторое время...
          </div>
        </CustomersInfoCard>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <CustomersInfoCard customPadding={"p-[30px]"}>
        <div className={"flex flex-col w-full min-h-[600px]"}>
          <div className={"w-full flex min-w-[600px] justify-end gap-x-3 py-3"}>
            <div className={"flex items-center gap-x-2"}>
              <BackArrowNavigate backUrl={`/customers/${id}/data/files`} />
            </div>
          </div>
          <div className={`${classes.scrollableContainer} flex flex-wrap gap-5 w-full h-full justify-start`}>
            {documents.map((doc: IMedia) => (
              <Document media={doc} key={doc.id} />
            ))}
          </div>
          {!isLastPageReached && (
            <div className={classes.loadMoreContainer}>
              <button
                disabled={isLoading}
                onClick={handleLoadMore}
                className={classes.loadMoreButton}
              >
                {isLoading && <div>Загрузка...</div>}
                {!isLoading && <div>Загрузить ещё</div>}
              </button>
            </div>
          )}
        </div>
      </CustomersInfoCard>
    </ContentContainer>
  );
};

export default CustomerDocumentsPage;
