import React, { FC, ReactNode } from "react";

interface IMessageContainerProps {
  customerId: string | undefined;
  senderId: string;
  children: ReactNode;
  id?: string;
}

const MessageContainer: FC<IMessageContainerProps> = ({
  customerId,
  children,
  senderId,
  id,
}) => {
  const isCustomerSender = customerId === senderId;
  return (
    <div
      id={id}
      className={`flex w-full ${
        isCustomerSender ? "justify-end" : "justify-start"
      }`}
    >
      {children}
    </div>
  );
};

export default MessageContainer;
