export const getDateFromUnixTime = (date: number): string => {
  // Проверка длины числа
  const dateInMilliseconds = date.toString().length === 10 ? date * 1000 : date;

  const sentDate = new Date(dateInMilliseconds);
  const day = sentDate.getDate();
  const month = sentDate.getMonth() + 1;
  const year = sentDate.getFullYear();

  const hours = sentDate.getHours();
  const minutes = sentDate.getMinutes();

  return `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${
    day < 10 ? "0" : ""
  }${day}.${month < 10 ? "0" : ""}${month}.${year}`;
};
