import { FC } from "react";
import { IMedia } from "../../common/types/media";
import UrlVideo from "./UrlVideo";
import BufferVideo from "./BufferVideo";

interface VideoProps {
  media: IMedia;
  handleThumbnailClick?: (messageId: string) => void;
}

export const Video: FC<VideoProps> = ({ media, handleThumbnailClick }) => {
  if ("url" in media) {
    return (
      <UrlVideo media={media} handleThumbnailClick={handleThumbnailClick} />
    );
  } else {
    return (
      <BufferVideo src={media.media as string} videoType={media.mediaType} />
    );
  }
};
