import React, {FC} from 'react';
import {CustomerLink} from "../../../../common/types/customer-link";
import LinkItemButton from "./LinkItemButton";
import {MdModeEdit} from "react-icons/md";
import {MdDelete} from "react-icons/md";
import { FaCopy } from "react-icons/fa";


interface LinkItemProps {
    link: CustomerLink;
    index: number;
    updateLink: (link: CustomerLink) => void;
    deleteLink: (link: CustomerLink) => void;
    copyLink: (link: CustomerLink) => Promise<void>;
}

const LinkItem: FC<LinkItemProps> = ({link, index, updateLink, deleteLink, copyLink}) => {
    return (
      <div key={link.id} className={"flex w-full"}>
        <p
          className={
            "w-[40px] flex justify-center items-center border border-[#252627]"
          }
        >
          {index}
        </p>
        <p
          className={"w-[200px] border px-2 py-1 border-[#252627] text-center"}
        >
          {link.name}
        </p>
        <p
          className={"w-[160px] px-2 py-1 border border-[#252627] text-center"}
        >
          {link.phone_number}
        </p>
        <p className={"w-[400px] px-2 py-1 border border-[#252627]"}>
          {link.redirect_url}
        </p>
        <LinkItemButton
          icon={<MdModeEdit size={20} />}
          cb={() => updateLink(link)}
        />
        <LinkItemButton icon={<FaCopy size={20} />} cb={() => copyLink(link)} />
        <p
          className={
            "w-[80px] border border-[#252627] flex items-center justify-center"
          }
        >
          {link.hits_count}
        </p>
        <p
          className={
            "w-[80px] border border-[#252627] flex items-center justify-center"
          }
        >
          {link.success_count}
        </p>
        <LinkItemButton
          icon={<MdDelete size={20} />}
          cb={() => deleteLink(link)}
        />
      </div>
    );
};

export default LinkItem;