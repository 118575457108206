import { FC } from "react";
import { DialogType } from "../../../common/types/dialog";

import UsersIcon from "../../../assets/icons/UsersIcon";
import UserOneIcon from "../../../assets/icons/UserOneIcon";
import AudioFile from "../../../assets/icons/AudioFile";

interface DialogTypeIconProps {
  dialogType: DialogType;
}

const DialogTypeIcon: FC<DialogTypeIconProps> = ({ dialogType }) => {
  if (dialogType === "user") {
    return <UserOneIcon />;
  } else if (dialogType === "group") {
    return <UsersIcon />;
  } else {
    return <AudioFile />;
  }
};

export default DialogTypeIcon;
