import { FC, useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { IUser } from "../../../../common/types/user";
import AddUserInput from "../add-user-dialog-window/AddUserInput";
import { IRole } from "../../../../common/types/role";
import EyeIcon from "../../../../assets/icons/EyeIcon";
import EyeSlash from "../../../../assets/icons/EyeSlash";
import { dataSourceDomains } from "../../../../constants";
import MultiSelect from "../../../../components/MultySelect";

interface EditUserFormProps {
  roles: IRole[];
  user: IUser;
  updateUser: (user: IUser) => void;
  isLoading: boolean;
}

const EditUserForm: FC<EditUserFormProps> = ({
  user,
  updateUser,
  roles,
  isLoading,
}) => {
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [shouldRenderPasswordFields, setShouldRenderPasswordFields] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  useEffect(() => {
    if (showPasswordFields) {
      setShouldRenderPasswordFields(true);
    } else {
      const timeoutId = setTimeout(
        () => setShouldRenderPasswordFields(false),
        300
      );
      return () => clearTimeout(timeoutId);
    }
  }, [showPasswordFields]);

  const validationSchema = yup.object().shape({
    nickname: yup
      .string()
      .required("Поле никнейм обязательное")
      .matches(/^.{2,}$/, "Никнейм должен содержать 2 и более символов"),
    role: yup.string().required("Роль обязательна"),
    password: yup
      .string()
      .test("password-required", "Пароль обязателен", function (value) {
        return !this.parent.showPasswordFields || !!value;
      })
      .min(8, "Пароль должен содержать 8 и более символов")
      .when("showPasswordFields", {
        is: true,
        then: (schema) => schema.required("Пароль обязателен"),
        otherwise: (schema) => schema.notRequired(),
      }),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Пароли должны совпадать")
      .test("repeat-password-required", "Повторите пароль", function (value) {
        return !this.parent.showPasswordFields || !!value;
      })
      .when("showPasswordFields", {
        is: true,
        then: (schema) => schema.required("Повторите пароль"),
        otherwise: (schema) => schema.notRequired(),
      }),
    allowedDomains: yup.array().of(yup.string()),
  });

  const form = useFormik({
    initialValues: {
      nickname: user.nickname,
      password: "",
      repeatPassword: "",
      role: user.role.id,
      showPasswordFields: showPasswordFields,
      allowedDomains: user.allowedDomains || [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        updateUser({
          ...user,
          nickname: values.nickname,
          password: values.password,
          role: {
            ...user.role,
            id: values.role,
          },
          allowedDomains: values.allowedDomains,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleChange = (field: string) => (e: any) => {
    form.handleChange(e);
    form.validateField(field);
  };

  const handleDomainChange = (options: string[]) => {
    form.setFieldValue("allowedDomains", options);
  };

  const handleBlur = (field: string) => (e: any) => {
    form.handleBlur(e);
    form.validateField(field);
  };

  const togglePasswordFields = () => {
    setShowPasswordFields(!showPasswordFields);
    form.setFieldValue("showPasswordFields", !showPasswordFields);
  };

  return (
    <form
      className="w-full min-w-[400px] px-[60px] pb-[30px] flex flex-col justify-center items-center"
      onSubmit={form.handleSubmit}
    >
      <h3 className="text-2xl font-bold self-start pb-4">
        Редактирование пользователя
      </h3>

      <label className="w-full flex gap-y-1 flex-col items-start justify-center">
        <span className="font-light text-base">Nickname</span>
        <AddUserInput
          placeholder="Nickname"
          isValid={!(form.touched.nickname && form.errors.nickname)}
          value={form.values.nickname}
          onBlur={handleBlur("nickname")}
          onChange={handleChange("nickname")}
          name="nickname"
          id="nickname"
        />
        <p className="h-[20px] font-light text-red-600 mt-1.5">
          {form.touched.nickname && form.errors.nickname}
        </p>
      </label>

      <label className="w-full flex gap-y-1 flex-col items-start justify-center">
        <span className="font-light text-base">Role</span>
        <select
          name="role"
          onChange={handleChange("role")}
          onBlur={handleBlur("role")}
          value={form.values.role}
          className="bg-[#252627] p-1 rounded-md mt-1 w-full cursor-pointer outline-none focus:outline-none"
        >
          {isLoading ? (
            <option>{user.role.name.toUpperCase()}</option>
          ) : (
            roles.map((role) => (
              <option key={role._id} value={role._id}>
                {role.name.toUpperCase()}
              </option>
            ))
          )}
        </select>
        <p className="h-[20px] font-light text-red-600 mt-1.5">
          {form.touched.role && form.errors.role}
        </p>
      </label>

      <div
        onClick={togglePasswordFields}
        className="bg-[#252627] px-2 py-1 rounded-md mt-1 w-full cursor-pointer outline-none focus:outline-none flex justify-between items-center"
      >
        <p>{showPasswordFields ? "Отмена" : "Изменить пароль"}</p>
        {/* <CevronDown
          className={`rotate-90 transition-all ${
            showPasswordFields ? "rotate-0" : ""
          }`}
        /> */}
      </div>

      <div
        className={`w-full transition-all duration-500 ease-in-out overflow-hidden pb-8 ${
          showPasswordFields ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        {shouldRenderPasswordFields && (
          <div
            className={`overflow-hidden transition-all duration-300 ${
              showPasswordFields
                ? "max-h-[1000px] opacity-100"
                : "max-h-0 opacity-0"
            }`}
          >
            <label className="w-full flex gap-y-1 flex-col items-start justify-center">
              <span className="font-light text-base">Пароль</span>
              <div className="relative w-full">
                <AddUserInput
                  placeholder="********"
                  isValid={!(form.touched.password && form.errors.password)}
                  value={form.values.password}
                  onBlur={handleBlur("password")}
                  onChange={handleChange("password")}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                />
                <button
                  onClick={() => setShowPassword(!showPassword)}
                  type="button"
                  className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600"
                >
                  {showPassword ? <EyeIcon /> : <EyeSlash />}
                </button>
              </div>
              <p className="h-[20px] font-light text-red-600 mt-1.5">
                {form.touched.password && form.errors.password}
              </p>
            </label>

            <label className="w-full flex gap-y-1 flex-col items-start justify-center">
              <span className="font-light text-base">Повторите пароль</span>
              <div className="relative w-full">
                <AddUserInput
                  placeholder="********"
                  isValid={
                    !(form.touched.repeatPassword && form.errors.repeatPassword)
                  }
                  value={form.values.repeatPassword}
                  onBlur={handleBlur("repeatPassword")}
                  onChange={handleChange("repeatPassword")}
                  name="repeatPassword"
                  type={showRepeatPassword ? "text" : "password"}
                  id="repeatPassword"
                />
                <button
                  onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                  type="button"
                  className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600"
                >
                  {showRepeatPassword ? <EyeIcon /> : <EyeSlash />}
                </button>
              </div>
              <p className="h-[20px] font-light text-red-600 mt-1.5">
                {form.touched.repeatPassword && form.errors.repeatPassword}
              </p>
            </label>
          </div>
        )}
      </div>

      <label className="w-full flex gap-y-1 flex-col items-start justify-center">
        <span className="font-light text-base">Allowed domains</span>
        <MultiSelect
          onChange={handleDomainChange}
          options={dataSourceDomains}
          defaultValue={form.values.allowedDomains}
        />
        <p className="h-[20px] font-light text-red-600 mt-1.5">
          {form.touched.allowedDomains && form.errors.allowedDomains}
        </p>
      </label>

      <button
        type="submit"
        disabled={!form.isValid || form.isSubmitting}
        className="mt-[20px] px-[80px] py-[10px] rounded-md transition-colors hover:bg-blue-600 bg-blue-500 cursor-pointer disabled:bg-gray-400 flex items-center justify-center"
      >
        {form.isSubmitting ? (
          <div className="loader !w-4 !h-4"></div>
        ) : (
          "Сохранить"
        )}
      </button>
    </form>
  );
};

export default EditUserForm;
