import React, { FC } from "react";
import BanlistChannelListItem from "./BanlistChannelListItem";
import {IBanlistChannel} from "../../../common/types/banlistChannel";

interface BanlistChannelsListProps {
  banlistChannels: IBanlistChannel[];
  deleteBanlistChannel: (banlistChannel: IBanlistChannel) => void;
}

const BanlistChannelsList: FC<BanlistChannelsListProps> = ({
  banlistChannels,
  deleteBanlistChannel,
}) => {
  return (
    <ul className={"flex flex-col gap-y-2 w-full"}>
      {banlistChannels.map((banlistChannel, index) => (
        <BanlistChannelListItem
          banlistChannel={banlistChannel}
          key={banlistChannel.tgId}
          index={index + 1}
          deleteBanlistChannel={(banlistChannel) =>
            deleteBanlistChannel(banlistChannel)
          }
          // updateBanlistChannel={(banlistChannel) =>
          //   updateBanlistChannel(banlistChannel)
          // }
        />
      ))}
    </ul>
  );
};

export default BanlistChannelsList;
