import React, { FC } from "react";
import { MdModeEdit, MdDelete } from "react-icons/md";
import {IUser} from "../../../common/types/user";
import UserItemButton from "./user-components/UserItemButton";

interface UserListItemProps {
  user: IUser;
  index: number;
  deleteUser: (user: IUser) => void;
  updateUser: (user: IUser) => void;
}

const UserListItem: FC<UserListItemProps> = ({ user, index, deleteUser, updateUser }) => {

    return (
      <div className={"flex hover:bg-[#1E1E1E]"}>
        <p
          className={
            "w-[40px] flex justify-center items-center border border-[#252627]"
          }
        >
          {index}
        </p>
        <p className={"flex-1 border px-2 py-1 border-[#252627] text-left"}>
          {user.nickname}
        </p>
        <p className={"flex-1 border px-2 py-1 border-[#252627] text-left"}>
          {user.role?.name}
        </p>
        <UserItemButton
          icon={<MdModeEdit size={20} />}
          cb={() => updateUser(user)}
        />
        <UserItemButton
          icon={<MdDelete size={20} />}
          cb={() => deleteUser(user)}
        />
      </div>
    );
}

export default UserListItem;
