import { FC } from "react";
import { IDialog } from "../../../common/types/dialog";
import Avatar from "../../../components/media-components/Avatar";
import DialogTypeIcon from "./DialogTypeIcon";
import { Link } from "react-router-dom";
import Counter from "../../../components/Counter";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { ICustomer } from "../../../common/types/customer";
import RefreshChatButton from "../../../components/buttons/RefreshChatButton";
import ChatIcon from "../../../assets/icons/ChatIcon";
import BanIcon from "../../../assets/icons/BanIcon";

interface DialogListItemProps {
  dialog: IDialog;
  handleBanChannel: (dialog: IDialog) => void;
}

const DialogListItem: FC<DialogListItemProps> = ({
  dialog,
  handleBanChannel,
}) => {
  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;
  const customerId = currentCustomer?.tgId;

  const style = `flex gap-x-2 items-center text-white p-2 transition-colors cursor-pointer rounded-lg ${
    dialog.isBanned
      ? "bg-red-500 hover:bg-[#da8c93]"
      : "bg-[#2d2e2f] hover:bg-[#1c1c1d]"
  }`;

  return (
    <div className="relative flex items-center justify-between p-1 bg-[#272727] rounded-md transition-colors hover:bg-[#3d3e3f]">
      <Link
        to={`/customers/${customerId}/data/dialogs/${dialog.id}`}
        className="absolute inset-0 z-10"
      />
      <div className="flex gap-x-3 items-center z-20">
        <Avatar url={dialog.avatarsUrl?.[0] || ""} />
        <DialogTypeIcon dialogType={dialog.dialogType} />
        <div className="flex flex-col">
          <p className="font-bold">{dialog.title}</p>
          <p className="text-xs text-gray-500">TG_id {dialog.tgId}</p>
          {dialog.phone && (
            <p className="text-xs text-gray-500">
              Номер телефона {dialog.phone}
            </p>
          )}
        </div>
      </div>
      <div className="flex gap-x-2 items-center z-20">
        <div
          onClick={() => {
            handleBanChannel(dialog);
          }}
          title="Забанить канал"
          className={style}
        >
          <BanIcon />
        </div>
        <RefreshChatButton customerId={customerId} dialogId={dialog.tgId} />
        <Counter count={dialog.messagesCount || 0} icon={<ChatIcon />} />
      </div>
    </div>
  );
};

export default DialogListItem;
