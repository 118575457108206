import React, { useRef, useEffect, useState } from "react";
import { IMessage } from "../../../../common/types/message";
import MessageContainer from "../messages-components/MessageContainer";
import Message from "../messages-components/Message";
import { MyPagination } from "../../../../common/types/plagination";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import api from "../../../../api/baseApi";
import {
  setMessages,
  updateMessageMedia,
} from "../../../../store/slices/messages-slice";
import { IDialog } from "../../../../common/types/dialog";
import { useAppDispatch } from "../../../../hooks/reduxHooks";
import { ITelegramUser } from "../../../../common/types/telegram-user";
import { useLocation, useParams } from "react-router-dom";


const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
};

const getScrollCookieKey = (dialogId: string) => `scrollPosition_${dialogId}`;

const MessagesWindow = () => {
  const dispatch = useAppDispatch();
  const messages = useAppSelector((state) => state.messages.messages);
  const dialog: IDialog = useAppSelector(
    (state) => state.dialogs.currentDialog
  ) as IDialog;
  const {id} = useParams<{ id: string }>();
  const customerId = id;
  const [messagesPagination, setMessagesPagination] = useState<MyPagination>({
    page: 0,
    limit: 50,
  });


  const messagesWindowRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mediaId = searchParams.get("imageId");

  const handleScroll = () => {
    if (messagesWindowRef.current) {
      const position = messagesWindowRef.current.scrollTop;
      setScrollPosition(position);
    }
  };

  const saveScrollPosition = () => {
    if (messagesWindowRef.current && dialog) {
      const currentScrollPosition = messagesWindowRef.current.scrollTop;
      const cookieKey = getScrollCookieKey(dialog.tgId);

      const savedPosition = getCookie(cookieKey);

      if (
        savedPosition === undefined ||
        parseInt(savedPosition, 10) !== currentScrollPosition
      ) {
        setCookie(cookieKey, currentScrollPosition.toString(), 7);
      }
    }
  };

  const restoreScrollPosition = () => {
    if (messagesWindowRef.current && dialog && !mediaId) {
      const cookieKey = getScrollCookieKey(dialog.tgId);
      const savedPosition = getCookie(cookieKey);

      if (savedPosition !== undefined) {
        const position = parseInt(savedPosition, 10);
        if (messagesWindowRef.current.scrollTop !== position) {
          messagesWindowRef.current.scrollTop = position;
        }
      }
    }
  };

  const fetchMessages = async (page?: number) => {
    try {
      const messagesData: any = await api(
        `/customers/messages?dialogId=${
          dialog.tgId
        }&customerId=${customerId}&page=${
          page ? page : messagesPagination.page
        }&limit=${messagesPagination.limit}`,
        {
          headers: {
            credentials: "include",
          },
        }
      );
      console.log("messagesData", messagesData);
      
      const fetchedMessages = messagesData.messages as IMessage[];

      if (isLoading) {
        setIsLoading(false);
      }
      const allMessages = [...fetchedMessages, ...(messages || [])];
      const uniqueMessages = Array.from(
        new Map(allMessages.map((msg) => [msg.tgId, msg])).values()
      ).sort((a, b) => parseInt(b.tgId) - parseInt(a.tgId));

      dispatch(
        setMessages({
          messages: uniqueMessages,
          total: messagesData.total,
        })
      );
    } catch (error) {
      console.log("Error fetching messages", error);
    }
  };

  const fetchNewMediaURL = async (messageId: string) => {
    try {
      const response: any = await api(`/customers/medias/${messageId}`, {
        headers: {
          credentials: "include",
        },
      });
      const newMedia = response;
      if (newMedia) {
        dispatch(updateMessageMedia({ messageId, newMedia }));
      }
    } catch (error) {
      console.log("Error loading new media", error);
    }
  };

  const checkScrollTop = async () => {
    if (!messagesWindowRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = messagesWindowRef.current;
    if (Math.abs(scrollTop) + clientHeight >= scrollHeight - 1) {
      setMessagesPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      await fetchMessages();
    }
  };

  const getMediaPlaceholder = (message: IMessage): string | null => {
    if (!message.text && (!message.media || message.media)) {
      return "";
    }
    return null;
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    const divRef = messagesWindowRef.current;

    if (divRef) {
      divRef.addEventListener("scroll", handleScroll);
      divRef.addEventListener("scroll", saveScrollPosition);
      divRef.addEventListener("scroll", checkScrollTop);

      return () => {
        if (divRef) {
          divRef.removeEventListener("scroll", handleScroll);
          divRef.removeEventListener("scroll", saveScrollPosition);
          divRef.removeEventListener("scroll", checkScrollTop);
        }
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    if (!isLoading && messages.length > 0 && mediaId) {
      const messageElement = document.getElementById(`message-${mediaId}`);
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isLoading, mediaId, messages]);

  useEffect(() => {
    if (!isLoading && messages.length > 0) {
      restoreScrollPosition();
    }
  }, [isLoading]);

  if (isLoading) {
    return <div>Загрузка ...</div>;
  }

  if (!messages) {
    return <div>Сообщения отсутствуют</div>;
  }

  const handleThumbnailClick = async (messageId: string) => {
    await fetchNewMediaURL(messageId);
  };

  return (
    <div>
      <div
        ref={messagesWindowRef}
        className={"flex flex-col-reverse gap-y-2 max-h-[600px] overflow-auto"}
      >
        {messages.map((message) => {
          const sender: ITelegramUser = dialog.users?.find(
            (user) => user.tgId === message.senderId
          ) as ITelegramUser;

          const mediaPlaceholder = getMediaPlaceholder(message);

          return (
            <MessageContainer
              customerId={customerId}
              senderId={message.senderId || ""}
              key={message.tgId}
              id={`message-${message.tgId}`}
            >
              <Message
                message={message}
                sender={sender}
                placeholder={mediaPlaceholder}
                handleThumbnailClick={handleThumbnailClick}
              />
            </MessageContainer>
          );
        })}
      </div>
    </div>
  );
};

export default MessagesWindow;
