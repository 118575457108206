import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import ChevronRight from "../../../assets/icons/ChevronRight";

interface SelectMenuItemButtonProps {
  title: string;
  subtitle: string;
  icon: ReactNode;
  href: string;
}

const SelectMenuItemButton: FC<SelectMenuItemButtonProps> = ({
  title,
  subtitle,
  icon,
  href,
}) => {
  return (
    <Link
      to={href}
      className={
        "flex justify-between w-full  min-w-[600px] items-center rounded-md px-5 py-2 text-[#d7d7d7] transition-colors bg-[#272727] hover:bg-[#3d3e3f] "
      }
    >
      <div className={"flex gap-x-3 items-center"}>
        {icon}
        <div className={"flex flex-col"}>
          <p className={"font-bold text-lg"}>{title}</p>
          <p className={"text-sm"}>{subtitle}</p>
        </div>
      </div>
      <div className={"rounded-full flex items-center justify-center"}>
        {<ChevronRight />}
      </div>
    </Link>
  );
};

export default SelectMenuItemButton;
