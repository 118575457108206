import { FC, useEffect, useState } from "react";
import EditUserForm from "./EditUserForm";
import { IUser } from "../../../../common/types/user";
import { IRole } from "../../../../common/types/role";
import api from "../../../../api/baseApi";
import CancelIcon from "../../../../assets/icons/CancelIcon";

interface EdItUserDialogWindowProps {
  user: IUser;
  hide: () => void;
  updateUser: (user: IUser) => void;
}

const EditUserDialogWindow: FC<EdItUserDialogWindowProps> = ({
  hide,
  updateUser,
  user,
}) => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRoles = async () => {
    try {
      setIsLoading(true);
      const roles: IRole[] = await api("roles", {
        headers: {
          credentials: "include",
        },
        method: "GET",
      });
      if (roles) {
        setRoles(roles);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <div
      onClick={hide}
      className={
        "fixed w-screen h-screen bg-black bg-opacity-70 top-0 cursor-pointer flex justify-center items-center"
      }
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-[#161616]  text-[#d7d7d7] cursor-default  rounded-md max-w-[750px] px-[25px] py-5  border-2 border-[#252627] w-full max-h-[800px] flex flex-col`}
      >
        <div className={"w-full flex justify-end px-[25px] "}>
          <button
            onClick={hide}
            className={
              "hover:bg-gray-500 transition-colors rounded-md p-2 flex justify-center items-center"
            }
          >
            <CancelIcon />
          </button>
        </div>

        <EditUserForm
          roles={roles}
          isLoading={isLoading}
          user={user}
          updateUser={(user) => updateUser(user)}
        />
      </div>
    </div>
  );
};

export default EditUserDialogWindow;
