import {useAppSelector} from "../hooks/reduxHooks";
import {Navigate} from "react-router-dom";
import React from "react";
import useCheckUserRole from "../hooks/useCheckUserRole";

interface ProtectedRouteProps {
  element: JSX.Element;
}

const CheckElevatedRight = ({ element }: ProtectedRouteProps) => {
  const currentUser = useAppSelector((state) => state.auth.user);
  const isAdmin = useCheckUserRole(currentUser);

  return isAdmin ? element : <Navigate to="/t" />;
};

export default CheckElevatedRight;
