import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IUser, IUserPagination} from "../../common/types/user";
import api from "../../api/baseApi";
import {IPagination} from "../../common/types/pagination";

export interface UserState {
    users: IUser[];
    currentUser?: IUser | null;
    loading: boolean;
    error: string | null;
    pagination: IPagination;
}
interface FetchUsersParams {
    page: number;
    take: number;
    name: string;
}

const initialState: UserState = {
    users: [],
    currentUser: null,
    loading: false,
    error: null,
    pagination: {
        "page": "1",
        "take": "1",
        "itemCount": 0,
        "pageCount": 0,
        "hasPreviousPage": false,
        "hasNextPage": false
    }
};



export const fetchUsers = createAsyncThunk(
    'users',
    async ({page, take, name}: FetchUsersParams, {rejectWithValue}) => {
        try {
            const result: IUserPagination = await api(`/users?page=${page}&take=${take}&name=${name}`, {
                headers: {
                    credentials: "include",
                },
            });
            return result;
        } catch (error: any) {
            return rejectWithValue(error.message || "Something went wrong");
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<IUserPagination>) => {
                state.users = action.payload.data;
                state.pagination = action.payload.pagination;
                state.loading = false;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const UsersReducer = userSlice.reducer;
