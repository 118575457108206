export const fakeBanlistChannels = [
    {
        id: "72846",
        title: 'Tech Insights',
        link: 'https://t.me/tech_insights'
    },
    {
        id: "37491",
        title: 'Crypto World',
        link: 'https://t.me/crypto_world'
    },
    {
        id: "18392",
        title: 'Marketing Guru',
        link: 'https://t.me/marketing_guru'
    },
    {
        id: "52674",
        title: 'Fitness Zone',
        link: 'https://t.me/fitness_zone'
    },
    {
        id: "39458",
        title: 'Travel Explorer',
        link: 'https://t.me/travel_explorer'
    },
    {
        id: "81724",
        title: 'Gaming Arena',
        link: 'https://t.me/gaming_arena'
    },
    {
        id: "92635",
        title: 'Startup Grind',
        link: 'https://t.me/startup_grind'
    },
    {
        id: "10456",
        title: 'Mindfulness Daily',
        link: 'https://t.me/mindfulness_daily'
    },
    {
        id: "59823",
        title: 'Eco Warriors',
        link: 'https://t.me/eco_warriors'
    },
    {
        id: "27465",
        title: 'AI Innovations',
        link: 'https://t.me/ai_innovations'
    }
];
