import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from "../../api/baseApi";
import {fakeBanlistChannels} from "../../MokeDataBase/banlistChannels";
import {IBanlistChannel} from "../../common/types/banlistChannel";

export interface BanlistChannelState {
    channels: IBanlistChannel[];
    currentChannel?: IBanlistChannel | null;
    loading: boolean;
    error: string | null;
};

const initialState: BanlistChannelState = {
    channels: [],
    currentChannel: null,
    loading: false,
    error: null,
};

export const fetchBanlistChannels = createAsyncThunk(
    'banlist_channels',
    async (_, { rejectWithValue }) => {
        try {
            if (process.env.REACT_APP_IS_MOCK_MODE !== "true")
            {
                const result: IBanlistChannel[] = await api(`/banlist_channels`, {
                    headers: {
                        credentials: "include",
                    },
                });
                return result;
            } else {
                return fakeBanlistChannels;
            }
        } catch (error: any) {
            return rejectWithValue(error.message || 'Something went wrong');
        }
    }
);

export const banlistChannelSlice = createSlice({
    name: 'banlistChannel',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBanlistChannels.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchBanlistChannels.fulfilled, (state, action: PayloadAction<IBanlistChannel[]>) => {
                state.channels = action.payload;
                state.loading = false;
            })
            .addCase(fetchBanlistChannels.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const BanlistChannelsReducer = banlistChannelSlice.reducer;
