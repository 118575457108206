import React, { FC } from "react";
import UserListItem from "./UserListItem";
import {IUser} from "../../../common/types/user";

interface UsersListProps {
  users: IUser[];
  deleteUser: (user: IUser) => void;
  updateUser: (user: IUser) => void;
}

const UsersList: FC<UsersListProps> = ({ users, deleteUser, updateUser }) => {
  return (
      <ul className={"flex flex-col gap-y-2 w-full"}>
        {users.map((user, index) => (
            <UserListItem
                user={user}
                key={user.id}
                index={index + 1}
                deleteUser={(user) => deleteUser(user)}
                updateUser={(user) => updateUser(user)}
            />
        ))}
      </ul>
  );
};

export default UsersList;
