import React, {FC, useState} from 'react';
import AvatarIcon from '../../../assets/icons/AvatarIcon';

interface BigAvatarProps {
    src: string;
}
const BigAvatar: FC<BigAvatarProps> = ({src}) => {
    const [isError, setIsError] = useState(false);

    if (src && !isError) {
        return <img
            onError={()=> setIsError(true)}
            className={'rounded-md w-[300px] h-[232px]'}
            src={src}
            alt={'User Avatar'}
        />
    } else {
        return (
            <div
                className={'rounded-md min-w-[240px] min-h-[232px] text-gray-600 bg-gray-300 flex items-center justify-center'}>
                <AvatarIcon/>
            </div>
        )
    }
};

export default BigAvatar;