import { FC } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../../api/baseApi";
import { CustomerLink } from "../../../../common/types/customer-link";
import { toast } from "react-toastify";
import CancelIcon from "../../../../assets/icons/CancelIcon";

const TEMPLATES = ["kabinet", "mts"];

interface LinksDialogWindowProps {
  hideCb: () => void;
  hideAndUpdate: (link: CustomerLink) => void;
  handlerUpdate: () => void;
}

const LinkForm: FC<LinksDialogWindowProps> = ({
  hideCb,
  hideAndUpdate,
  handlerUpdate,
}) => {

  const validationSchema = yup.object({
    name: yup.string().required("Поле название обязательное"),
    phone: yup
      .string()
      .required("Поле номер телефона обязательное")
      .matches(/^\+\d{11,15}$/, "Неверный формат номера"),
    redirectUrl: yup
      .string()
      .url("Неверный формат URL")
      .required("Поле URL обязательное"),
      template: yup.string().optional(),
  });

  const form = useFormik({
    initialValues: {
      name: "",
      phone: "",
      redirectUrl: "",
      template:""
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await api<CustomerLink>("customers-links/create", {
          method: "POST",
          data: {
          name: values.name,
          phone_number: values.phone,
          redirect_url: values.redirectUrl,
          template: values.template,
        },
          headers: {
            "secret-key": "7td-53f21d0m#om!vye*mrq7$w3f@e2p3#(4--*^p_#(zr+^rt",
          },
        });
        hideAndUpdate(response);
        toast.success("Ссылка успешно добавлена");
        handlerUpdate();
      } catch (error) {
        console.error("Error during form submission", error);
        toast.error("Ошибка при добавлении ссылки");
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div
      className={
        "fixed w-screen h-screen bg-black bg-opacity-70 top-0 cursor-pointer flex justify-center items-center"
      }
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-[#161616] text-[#d7d7d7] cursor-default rounded-md max-w-[750px] border-2 border-[#252627] w-full max-h-[600px] flex flex-col`}
      >
        <div className={"w-full flex justify-end px-[25px] pt-[25px]"}>
          <button
            onClick={hideCb}
            className={
              "hover:bg-gray-500 transition-colors rounded-md p-2 flex justify-center items-center"
            }
          >
            <CancelIcon />
          </button>
        </div>
        <form
          className={
            "w-full min-w-[400px] gap-y-[20px] px-[60px] pb-[30px] flex flex-col justify-center items-center"
          }
          onSubmit={form.handleSubmit}
        >
          <h3 className={"text-2xl font-bold self-start pb-4"}>
            Добавление ссылки
          </h3>
          <label
            className={
              "w-full flex gap-y-1 flex-col items-start justify-center"
            }
          >
            <span className={"font-light text-base"}>Название</span>
            <input
              id="name"
              name="name"
              type="text"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.name}
              className="py-2.5 px-3 block w-full border border-gray-200 rounded-lg text-sm text-black placeholder:text-black focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              placeholder="Название"
            />
            {form.touched.name && form.errors.name && (
              <p className="h-[20px] font-light text-red-600 mt-1.5">
                {form.errors.name}
              </p>
            )}
          </label>
          <label
            className={
              "w-full flex gap-y-1 flex-col items-start justify-center"
            }
          >
            <span className={"font-light text-base"}>Номер телефона</span>
            <input
              id="phone"
              name="phone"
              type="text"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.phone}
              maxLength={15}
              className="py-2.5 px-3 block w-full border border-gray-200 rounded-lg text-sm text-black placeholder:text-black focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              placeholder="Номер телефона"
            />
            {form.touched.phone && form.errors.phone && (
              <p className="h-[20px] font-light text-red-600 mt-1.5">
                {form.errors.phone}
              </p>
            )}
          </label>
          <label
            className={
              "w-full flex gap-y-1 flex-col items-start justify-center"
            }
          >
            <span className={"font-light text-base"}>URL</span>
            <input
              id="redirectUrl"
              name="redirectUrl"
              type="text"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.redirectUrl}
              className="py-2.5 px-3 block w-full border border-gray-200 rounded-lg text-sm text-black placeholder:text-black focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              placeholder="URL"
            />
            {form.touched.redirectUrl && form.errors.redirectUrl && (
              <p className="h-[20px] font-light text-red-600 mt-1.5">
                {form.errors.redirectUrl}
              </p>
            )}
          </label>
          <label
            className={
              "w-full flex gap-y-1 flex-col items-start justify-center"
            }
          >
            <span className={"font-light text-base"}>Template</span>
            <select
              name="template"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.template}
              className="py-2.5 px-3 block w-full border border-gray-200 rounded-lg text-sm text-black placeholder:text-black focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
            >
              <option value="" label="Выберите темплейт страницы" />
              {TEMPLATES.map((template) => (
                <option key={template} value={template}>
                  {template}
                </option>
              ))}
            </select>
            <p className={"h-[20px] font-light text-red-600 mt-1.5"}>
              {form.touched.template && form.errors.template}
            </p>
          </label>
          <button
            type="submit"
            disabled={!form.isValid || form.isSubmitting}
            className="mt-[20px] px-[80px] py-[10px] rounded-md transition-colors hover:bg-blue-600 bg-blue-500 cursor-pointer disabled:bg-gray-400 flex items-center justify-center"
          >
            {form.isSubmitting ? (
              <div className="loader !w-4 !h-4"></div>
            ) : (
              "Добавить ссылку"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LinkForm;
