import React, { FC } from "react";

interface PaginationProps {
  currentPage: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  pageCount: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  currentPage,
  onPageChange,
  hasPreviousPage,
  hasNextPage,
  pageCount,
}) => {
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= pageCount) {
      onPageChange(page);
    }
  };

  const createRangeArray = (
    num: number,
    min: number,
    max: number
  ): number[] => {
    let result: number[] = [];
    for (let i = num - 3; i <= num + 3; i++) {
      if (i >= min && i <= max) {
        result.push(i);
      }
    }
    return result;
  };

  return (
    <nav className="flex flex-col items-center gap-y-2">
      <div className="flex justify-end items-center gap-x-1">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={!hasPreviousPage}
          type="button"
          className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm rounded-lg text-white hover:text-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
        >
          <svg
            aria-hidden="true"
            className="hidden flex-shrink-0 size-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m15 18-6-6 6-6"></path>
          </svg>
          <span>Previous</span>
        </button>
        <div className="flex items-center gap-x-1">
          {currentPage > 4 && (
            <button
              onClick={() => handlePageChange(currentPage - 4)}
              type="button"
              className={`border border-[#252627] min-h-[38px] min-w-[38px] flex justify-center items-center py-2 px-3 text-sm rounded-lg focus:outline-none text-gray-800 hover:bg-gray-100 `}
            >
              ...
            </button>
          )}
          {createRangeArray(currentPage, 1, pageCount).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              type="button"
              className={`border border-[#252627] min-h-[38px] min-w-[38px] flex justify-center items-center py-2 px-3 text-sm rounded-lg focus:outline-none ${
                currentPage === page
                  ? "bg-gray-200 text-gray-800"
                  : "text-gray-800 hover:bg-gray-100"
              }`}
            >
              {page}
            </button>
          ))}
          {pageCount - currentPage > 3 && (
            <button
              onClick={() => handlePageChange(currentPage + 4)}
              type="button"
              className={`border border-[#252627] min-h-[38px] min-w-[38px] flex justify-center items-center py-2 px-3 text-sm rounded-lg focus:outline-none text-gray-800 hover:bg-gray-100 `}
            >
              ...
            </button>
          )}
        </div>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={!hasNextPage}
          type="button"
          className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm rounded-lg text-white hover:bg-gray-100 hover:text-black focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none  "
        >
          <span>Next</span>
          <svg
            aria-hidden="true"
            className="hidden flex-shrink-0 size-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m9 18 6-6-6-6"></path>
          </svg>
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
