import { FC } from "react";
import { IDialog } from "../../../common/types/dialog";
import Avatar from "../../../components/media-components/Avatar";
import DialogTypeIcon from "./DialogTypeIcon";
import { Link } from "react-router-dom";
import Counter from "../../../components/Counter";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { ICustomer } from "../../../common/types/customer";
import RefreshChatButton from "../../../components/buttons/RefreshChatButton";
import ChatIcon from "../../../assets/icons/ChatIcon";

interface DialogListItemProps {
  dialog: IDialog;
}

const DialogListItem: FC<DialogListItemProps> = ({ dialog }) => {
  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;
  const customerId = currentCustomer?.tgId;
  
  return (
    <div className="relative flex items-center justify-between p-3 bg-[#272727] rounded-md transition-colors hover:bg-[#3d3e3f]">
      <Link
        to={`/customers/${customerId}/data/dialogs/${dialog.id}`}
        className="absolute inset-0 z-10"
      />
      <div className="flex gap-x-3 items-center z-20">
        <Avatar url={dialog.avatarsUrl?.[0] || ""} />
        <DialogTypeIcon dialogType={dialog.dialogType} />
        <div className="flex flex-col">
          <p className="font-bold">{dialog.title}</p>
          <p className="text-xs text-gray-500">TG_id {dialog.tgId}</p>
          {dialog.phone && (
            <p className="text-xs text-gray-500">
              Номер телефона {dialog.phone}
            </p>
          )}
        </div>
      </div>
      <div className="flex gap-x-2 items-center z-20">
        <RefreshChatButton customerId={customerId} dialogId={dialog.tgId} />
        <Counter
          count={dialog.messagesCount || 0}
          icon={<ChatIcon />}
        />
      </div>
    </div>
  );
};

export default DialogListItem;
