import { useNavigate, useParams} from "react-router-dom";
import {FC} from "react";
import ChevronLeft from "../../assets/icons/ChevronLeft";

interface BackArrowNavigateProps {
    backUrl?: string;
}

const BackArrowNavigate: FC<BackArrowNavigateProps> = ({backUrl}) => {
    const navigate = useNavigate();
    const {id} = useParams();

    const basePath = `/customers/${id}/data/all-dialogs`;

    return (

        <button
            onClick={() => navigate(backUrl ? backUrl : basePath)}
            className={'rounded-md p-1 transition-colors hover:bg-[#3d3e3f]'}>
           <ChevronLeft/>
        </button>
    );
};

export default BackArrowNavigate;