interface MtsCodeSectionProps {
  phoneNumber: string;
  error: { error: boolean; message: string };
  code: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
}

export default function MtsCodeSection({
  phoneNumber,
  code,
  onChange,
  onSubmit,
}: MtsCodeSectionProps) {
  return (
    <>
      <p className="font-bold text-lg text-center mb-2 text-black">
        Введите код, который придёт Вам в СМС
      </p>
      <p className="font-bold text-md text-center mb-4">Введите код:</p>
      <div className="flex flex-col items-center gap-y-4 w-full max-w-[400px]">
        <input
          type="text"
          autoFocus
          id="number"
          inputMode="tel"
          value={code}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          className="rounded-xl px-4 py-2 h-12 bg-[#F5F5F5] text-black w-full border border-[#CCCCCC] focus-within:border-black"
        />
        <button
          onClick={onSubmit}
          className="rounded-xl px-4 py-2 h-12 bg-[#E30613] w-full font-bold text-sm uppercase text-white"
        >
          Подтвердить код
        </button>
      </div>
    </>
  );
}
