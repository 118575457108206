import { useEffect, useState, type FC } from "react";
import { ICustomer } from "../../common/types/customer";
import { useURLParams } from "../../common/util/saveSearchParams";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchUsers } from "../../store/slices/user-slice";

interface CustomerSettingsProps {
  customer: ICustomer | null;
  onCancel: () => void;
  onSave: (customerId:string, owners: string[]) => void;
}

const CustomerSettings: FC<CustomerSettingsProps> = ({
  customer,
  onCancel,
  onSave,
}) => {
  const dispatch = useAppDispatch();
  const { page, search } = useURLParams();

  const [owners, setOwners] = useState<string[]>(customer?.owners || []);

  const users = useAppSelector((state) => state.user.users);

  useEffect(() => {
    dispatch(fetchUsers({ page: page, take: 50, name: search }));
  }, [dispatch, page, search]);

  const handleCheckboxChange = (userId: string) => {
    setOwners((prevOwners) =>
      prevOwners.includes(userId)
        ? prevOwners.filter((id) => id !== userId)
        : [...prevOwners, userId]
    );
  };

  const handleSave = () => {
    if (!customer) return;
    customer.id && onSave(customer.id, owners);
  };
  
  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 w-1/3 rounded shadow-md">
        <h2 className="text-lg font-bold mb-4">Настройки клиента</h2>
        <div className="mb-8">
          {users.map((user) => (
            <div key={user.id} className="flex items-center mb-2 gap-x-3">
              <input
                type="checkbox"
                id={`user-${user.id}`}
                checked={owners.includes(user.id)}
                onChange={() => handleCheckboxChange(user.id)}
                className="mr-2"
              />
              <label htmlFor={`user-${user.id}`} className="text-md">
                {user.nickname}
              </label>
              <label htmlFor={`user-${user.role}`} className="text-md font-bold">
                Role: {user.role.name}
              </label>
            </div>
          ))}
        </div>
        <div className="flex justify-end gap-4">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-red-500 text-white rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerSettings;
