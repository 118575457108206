import React, { FC } from "react";
// import { IContact } from "../../../common/types/telegram-user";
import Avatar from "../../../components/media-components/Avatar";
import OpenContactDialogButton from "./OpenContactDialogButton";

interface IContactItemProps {
  contact: any;
  customerId: string;
}

const ContactItem: FC<IContactItemProps> = ({ contact, customerId }) => {
  return (
    <div
      className={
        "w-full flex justify-between px-4 bg-[#272727] h-[72px] rounded-md items-center transition-colors hover:bg-[#3d3e3f]"
      }
    >
      <div className={"flex items-center gap-x-5"}>
        <Avatar
          url={contact?.avatarsUrl?.[0] || ""}
          customSize={"w-[40px] h-[40px]"}
        />
        <div className={"flex flex-col font-bold"}>
          <p>
            {contact.firstName} {contact.lastName}
          </p>
          <p className={"text-[#5e6061] text-sm"}>
            {contact.username ? `@${contact.username}` : ""}
          </p>
          <p className={"text-[#5e6061] text-sm"}>TG_id: {contact.tgId}</p>
        </div>
      </div>
      <div className={"flex items-center h-full gap-x-10"}>
        {contact.phone && (
          <p className={`text-sm font-bold bg-[#191919] p-1 rounded-md`}>
            +{contact.phone}
          </p>
        )}
        {contact.dialogId && (
          <OpenContactDialogButton
            dialogId={contact.dialogId ?? ""}
            customerId={customerId}
          />
        )}
      </div>
    </div>
  );
};

export default ContactItem;
