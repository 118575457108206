import React from "react";
import api from "../../api/baseApi";
import { toast } from "react-toastify";
import { IContact } from "../../common/types/telegram-user";
import RefreshIcon from "../../assets/icons/RefreshIcon";

interface RefreshContactButtonProps {
  customerId: string;
  contacts: IContact[];
  onRefresh: () => void;
}

const RefreshContactButton: React.FC<RefreshContactButtonProps> = ({
  customerId,
  contacts,
  onRefresh,
}) => {
  const clearCache = async () => {
    try {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName))
      );
    } catch (error) {
      console.error("Error clearing cache:", error);
    }
  };

  const refreshContacts = async () => {
    await clearCache();
    try {
      const response = await api(`customers/refresh-contacts/${customerId}`, {
        method: "PUT",
        data: contacts,
      });

      if (response) {
        toast.success("Контакты успешно обновлены");
        console.log(response);
        onRefresh();
      }
    } catch (error) {
      toast.error("Ошибка обновления контактов");
      console.error("Error refreshing contacts:", error);
    }
  };

  return (
    <div className="flex justify-center">
      <button
        onClick={refreshContacts}
        className="flex gap-x-2 items-center text-white p-2.5 transition-colors rounded-lg bg-[#2d2e2f] hover:bg-[#3d3e3f]"
      >
        <span className="text-sm">Обновить контакты</span>
        <RefreshIcon />
      </button>
    </div>
  );
};

export default RefreshContactButton;
