import React, {FC, ReactNode} from 'react';
import background from '../../assets/backgrounds/background.png';

interface AdminBackgroundProps {
    children: ReactNode;
}

const AdminBackground: FC<AdminBackgroundProps> = ({children}) => {
    return (
      <div
        className={"w-screen h-screen bg-repeat"}
        style={{ backgroundImage: `url(${background})` }}
      >
        {children}
      </div>
    );
};

export default AdminBackground;