import { FC } from "react";
import { CustomerLink } from "../../../../common/types/customer-link";
import EditLinkForm from "./EditLinkForm";
import CancelIcon from "../../../../assets/icons/CancelIcon";

interface EdItLinkDialogWindowProps {
  link: CustomerLink;
  hide: () => void;
  updateLink: (link: CustomerLink) => void;
}

const EdItLinkDialogWindow: FC<EdItLinkDialogWindowProps> = ({
  hide,
  updateLink,
  link,
}) => {
  return (
    <div
      onClick={hide}
      className={
        "absolute w-screen h-screen bg-black bg-opacity-70 top-0 cursor-pointer flex justify-center items-center"
      }
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-[#161616]  text-[#d7d7d7] cursor-default  rounded-md max-w-[750px] px-[25px] py-5  border-2 border-[#252627] w-full max-h-[600px] flex flex-col`}
      >
        <div className={"w-full flex justify-end px-[25px] "}>
          <button
            onClick={hide}
            className={
              "hover:bg-gray-500 transition-colors rounded-md p-2 flex justify-center items-center"
            }
          >
            <CancelIcon />
          </button>
        </div>

        <EditLinkForm link={link} updateLink={(link) => updateLink(link)} />
      </div>
    </div>
  );
};

export default EdItLinkDialogWindow;
