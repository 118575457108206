import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IMessage } from "../../common/types/message";
import { IMedia } from "../../common/types/media";

interface MessagesData {
  messages: IMessage[];
  total: number;
}

export interface MessagesState {
  messages: IMessage[];
  currentMessage?: IMessage | null;
  total: number;
}

const initialState: MessagesState = {
  messages: [],
  currentMessage: null,
  total: 0,
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<MessagesData>) => {
      state.messages = action.payload.messages;
      state.total = action.payload.total;
    },
    setCurrentMessage: (state, action: PayloadAction<IMessage>) => {
      state.currentMessage = action.payload;
    },
    updateMessageMedia: (
      state,
      action: PayloadAction<{ messageId: string; newMedia: string }>
    ) => {
      const { messageId, newMedia } = action.payload;
      const messageIndex = state.messages.findIndex(
        (msg) => msg.tgId === messageId
      );

      if (messageIndex !== -1) {
        let updatedMedia = state.messages[messageIndex].media;
        updatedMedia = { ...updatedMedia, url: newMedia };

        state.messages[messageIndex] = {
          ...state.messages[messageIndex],
          media: updatedMedia,
        };
      }
    },
  },
});

export const { setMessages, setCurrentMessage, updateMessageMedia } =
  messagesSlice.actions;

export const MessagesReducer = messagesSlice.reducer;
