import {useParams} from "react-router-dom";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import ContentContainer from "../../../components/layouts/ContentContainer";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import {ICustomer} from "../../../common/types/customer";
import {selectCustomerByTgId, setCurrentCustomer} from "../../../store/slices/customer-slice";
import BigAvatar from "./BigAvatar";
import React, {useEffect} from "react";
import CustomerInfoButton from "../../../components/buttons/CustomerInfoButton";
import {IoChatbubbles} from "react-icons/io5";
import {MdAttachFile} from "react-icons/md";
import {IoIosContact} from "react-icons/io";
import {getShowCustomerStatus} from "../../../common/service/customer-info";
import DownloadButton from "./DownloadButton";
import api from "../../../api/baseApi";
import {socket} from "../../../socket";
import BackArrowNavigate from "../../../components/buttons/BackArrowNavigate";


const CustomerInfoPage = () => {
    const {id} = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const customer: ICustomer = useAppSelector(selectCustomerByTgId(id as string));
    const currentCustomer = useAppSelector((state) => {
        return state.customer.currentCustomer;
    });


    const onCustomerNeedUpdate = async () => {
        console.log('customer need update');
        await fetchCustomer();
    }

    useEffect(() => {
        dispatch(setCurrentCustomer(customer));
        fetchCustomer();

        socket.on("customer-need-update", onCustomerNeedUpdate);

        return () => {
            socket.off("customer-need-update", onCustomerNeedUpdate);
        };

    }, []);

    const fetchCustomer = async () => {
        try {
            const response = await api<ICustomer>(`/customers/${id}`, {
                method: 'get',
            });
            dispatch(setCurrentCustomer(response));
        } catch (error) {
            console.log(error)
        }
    }

    if (!id || !currentCustomer) {
        return <div>Customer not found</div>
    }


    return (
        <ContentContainer>
            <CustomersInfoCard customPadding={"p-[60px]"}>
                <div className={'flex flex-col gap-y-20 w-full'}>
                    <div className={'flex gap-x-20 w-full'}>
                        <div className={'flex flex-col gap-y-5'}>
                            <BigAvatar src={currentCustomer.userAvatars?.[0] || ''}/>
                            <DownloadButton customer={currentCustomer}/>
                        </div>
                        <div className={'flex flex-col w-full'}>
                            <div className={"flex border-b border-[#C7C7C7]"}>
                                <div className={'flex flex-col pb-4 w-full'}>
                                    <p className={'font-bold text-xl'}>
                                        {currentCustomer.firstName ?? ''} {currentCustomer.lastName ?? ''}
                                    </p>
                                    <p>
                                        @{currentCustomer.username}
                                    </p>
                                </div>
                                <div className={"flex justify-start place-items-start"}>
                                    <BackArrowNavigate backUrl={"/customers"}/>
                                </div>
                            </div>

                            <div className={'flex justify-between pr-[50px]'}>
                                <div className={'flex gap-x-10 font-bold'}>
                                    <div className={'flex flex-col py-5 gap-y-5'}>
                                        <div className={'flex flex-col'}>
                                            <p className={'text-sm text-[#C7C7C7]'}>Телефон</p>
                                            <p>{currentCustomer.phone}</p>
                                        </div>
                                        <div className={'flex flex-col'}>
                                            <p className={'text-sm text-[#C7C7C7]'}>ID</p>
                                            <p>{currentCustomer.tgId}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'flex gap-x-10 font-bold'}>
                                    <div className={'flex flex-col py-5 gap-y-5'}>
                                        <div className={'flex flex-col'}>
                                            <p className={'text-sm text-[#C7C7C7]'}>Статус</p>
                                            <p>{getShowCustomerStatus(currentCustomer.status)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={'flex w-full justify-between'}>
                        <CustomerInfoButton
                            icon={<IoChatbubbles size={25}/>}
                            text={'Диалоги'}
                            href={`/customers/${currentCustomer.tgId}/data/all-dialogs`}
                        />
                        <CustomerInfoButton
                            icon={<MdAttachFile size={25}/>}
                            text={'Вложения'}
                            href={`/customers/${currentCustomer.tgId}/data/files`}
                        />
                        <CustomerInfoButton
                            icon={<IoIosContact size={25}/>}
                            text={'Контакты'}
                            href={`/customers/${currentCustomer.tgId}/data/contacts`}
                        />
                    </div>
                </div>
            </CustomersInfoCard>
        </ContentContainer>
    );
};

export default CustomerInfoPage;