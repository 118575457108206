// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commonPagesStyles_loadMoreContainer__z8M2J {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.commonPagesStyles_loadMoreButton__7Tff1 {
  width: auto;
  max-width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: #2d2e2f;
  color: white;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s;
  display: flex;
}

.commonPagesStyles_loadMoreButton__7Tff1:disabled {
  cursor: not-allowed;
  background-color: darkgrey;
}

.commonPagesStyles_loadMoreButton__7Tff1:disabled:hover {
  cursor: not-allowed;
  background-color: darkgrey;
}

.commonPagesStyles_loadMoreButton__7Tff1:hover {
  background-color: #1c1c1d;
}

.commonPagesStyles_scrollableContainer__Re\\+c4 {
  max-height: 600px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin-pages/dialog/dialog-windows/commonPagesStyles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,iCAAiC;EACjC,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".loadMoreContainer {\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.loadMoreButton {\n  width: auto;\n  max-width: 100%;\n  text-align: center;\n  cursor: pointer;\n  background-color: #2d2e2f;\n  color: white;\n  padding: 10px;\n  border-radius: 8px;\n  transition: background-color 0.3s;\n  display: flex;\n}\n\n.loadMoreButton:disabled {\n  cursor: not-allowed;\n  background-color: darkgrey;\n}\n\n.loadMoreButton:disabled:hover {\n  cursor: not-allowed;\n  background-color: darkgrey;\n}\n\n.loadMoreButton:hover {\n  background-color: #1c1c1d;\n}\n\n.scrollableContainer {\n  max-height: 600px;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadMoreContainer": `commonPagesStyles_loadMoreContainer__z8M2J`,
	"loadMoreButton": `commonPagesStyles_loadMoreButton__7Tff1`,
	"scrollableContainer": `commonPagesStyles_scrollableContainer__Re+c4`
};
export default ___CSS_LOADER_EXPORT___;
