import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { clearSelectedImage } from "../../../store/slices/selectedImage-slice";
import SelectedImageOptions from "./SelectedImageOptions";

const SelectedImage = () => {
  const dispatch = useAppDispatch();
  const selectedImage = useAppSelector((state) => state.selectedImage);

  const src = selectedImage ? selectedImage["url"] : "";

  return (
    <div
      onClick={() => dispatch(clearSelectedImage())}
      className={`fixed cursor-pointer w-screen z-20 h-screen bg-black bg-opacity-80 top-0 ${
        !selectedImage && "hidden"
      } flex justify-center items-center`}
    >
      <div className={"flex flex-col gap-x-5"}>
        <img
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className={
            "max-w-[600px] max-h-[800px] h-auto object-contain cursor-default"
          }
          src={src}
          alt=""
        />
        {selectedImage && <SelectedImageOptions image={selectedImage} />}
      </div>
    </div>
  );
};

export default SelectedImage;
