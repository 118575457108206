import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AuthReducer, authSlice } from "./slices/auth-slice";
import { CustomersReducer, customerSlice } from "./slices/customer-slice";
import { DialogReducer, dialogsSlice } from "./slices/dialogs-slice";
import { MessagesReducer, messagesSlice } from "./slices/messages-slice";
import {
  SelectedImageReducer,
  selectedImageSlice,
} from "./slices/selectedImage-slice";
import { UsersReducer, userSlice } from "./slices/user-slice";
import {
  banlistChannelSlice,
  BanlistChannelsReducer,
} from "./slices/banlist-slice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    authSlice.name,
    // customerSlice.name,
    dialogsSlice.name,
    messagesSlice.name,
    banlistChannelSlice.name,
    // selectedImageSlice.name,
  ],
};

const reducer = persistReducer(
  persistConfig,
  combineReducers({
    [authSlice.name]: AuthReducer,
    [customerSlice.name]: CustomersReducer,
    [userSlice.name]: UsersReducer,
    [banlistChannelSlice.name]: BanlistChannelsReducer,
    [dialogsSlice.name]: DialogReducer,
    [messagesSlice.name]: MessagesReducer,
    [selectedImageSlice.name]: SelectedImageReducer,
  })
);

const makeStore = () => {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};

export const store = makeStore();

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
