import { FC } from "react";
interface ILoadingButtonProps {
  className?: string;
}

const LoadingButton: FC<ILoadingButtonProps> = ({ className }) => {
  return (
    <div
      className={` flex h-[54px] mt-6 max-w-[360px] items-center justify-center ${className}`}
    >
      <div className="loader"></div>
    </div>
  );
};

export default LoadingButton;
