import React, { useState } from "react";
import { ReactComponent as EmblemIcon } from "../../../assets/emblem.svg";
import api from "../../../api/baseApi";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { login } from "../../../store/slices/auth-slice";
import { useNavigate } from "react-router-dom";
import { Tokens } from "../../../common/types/token";
import { setTokens } from "../../../common/util/tokens";
import { useFormik } from "formik";
import * as yup from "yup";
import { FaSpinner, FaRegEye, FaRegEyeSlash } from "react-icons/fa";

export default function AdminLogin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = yup.object({
    nickname: yup.string().required("Поле никнейм обязательное"),
    password: yup.string().required("Пароль обязателен"),
  });

  const form = useFormik({
    initialValues: {
      nickname: "",
      password: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await api<Tokens>("/auth/admin/sign-in", {
          method: "POST",
          data: values,
        });
        if (response?.refresh_token && response?.access_token) {
          dispatch(login());
          setTokens(response);
          navigate("/customers");
        }

      } catch (error) {
        console.error("Error during form submission", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-100">
      <div className="p-5 flex flex-col bg-white shadow rounded-2xl">
        <div className="text-center mb-3">
          <h1 className="text-xl font-semibold text-gray-800">
            Enter your credentials to sign in.
          </h1>
        </div>
        <div className="flex justify-center mb-3">
          <EmblemIcon />
        </div>

        <form onSubmit={form.handleSubmit}>
          <div className="space-y-5">
            <div className="space-y-3">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800">
                  Nickname
                </label>
                <input
                  id="nickname"
                  name="nickname"
                  type="text"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.nickname}
                  className="py-2.5 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  placeholder="Nickname"
                />
                {form.touched.nickname && form.errors.nickname && (
                  <p className="h-[20px] font-light text-red-600 mt-1.5">
                    {form.errors.nickname}
                  </p>
                )}
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800">
                  Password
                </label>

                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.password}
                    type={showPassword ? "text" : "password"}
                    className="py-2.5 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    placeholder="********"
                  />
                  <button
                    onClick={() => setShowPassword(!showPassword)}
                    type="button"
                    className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600"
                  >
                    {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  </button>
                </div>
                {form.touched.password && form.errors.password && (
                  <p className="h-[20px] font-light text-red-600 mt-1.5">
                    {form.errors.password}
                  </p>
                )}
              </div>

              <button
                type="submit"
                disabled={!form.isValid || form.isSubmitting}
                className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              >
                {form.isSubmitting ? (
                  <FaSpinner className="animate-spin mr-2" />
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
