import React from "react";
import api from "../../api/baseApi";
import { toast } from "react-toastify";
import RefreshIcon from "../../assets/icons/RefreshIcon";

interface RefreshChatButtonProps {
  customerId: string;
  dialogId: string;
  offsetDate?: number;
}

const RefreshChatButton: React.FC<RefreshChatButtonProps> = ({
  customerId,
  dialogId,
}) => {
  const refreshChat = async () => {
    try {
      const response = await api(
        `parser/messagesByDialog/${dialogId}?customerId=${customerId}`,
        {
          method: "GET",
        }
      );

      if (response) {
        toast.success("Чат успешно обновлён");
        console.log(response);
      }
    } catch (error) {
      toast.error("Ошибка обновления чата");
      console.error("Error refreshing chat:", error);
    }
  };

  return (
    <button
      onClick={refreshChat}
      title="Обновить чат"
      className="flex gap-x-2 items-center text-white p-2 transition-colors rounded-lg bg-[#2d2e2f] hover:bg-[#1c1c1d]"
    >
      <RefreshIcon />
    </button>
  );
};

export default RefreshChatButton;
