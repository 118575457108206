import React, { useEffect, useState } from "react";
import api from "../../../api/baseApi";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import Loader from "../../../components/loader/Loader";
import BanlistChannelsList from "./BanlistChannelsList";
import RemoveBanlistChannelDialogWindow from "./remove-banList-dialog-window/RemoveBanListDialogWindow";
import { IBanlistChannel } from "../../../common/types/banlistChannel";
import { fetchBanlistChannels } from "../../../store/slices/banlist-slice";

export default function BanlistChannelsPage() {
  const [removedBanlistChannel, setRemovedBanlistChannel] =
    useState<IBanlistChannel | null>(null);

  const dispatch = useAppDispatch();
  const banlistChannelsData = useAppSelector(
    (state) => state.banlistChannel.channels
  );
  const isLoading = useAppSelector((state) => state.banlistChannel.loading);

  const deleteBanlistChannel = async (dialog: IBanlistChannel) => {
    try {
      await api("/customers/ban-dialog", {
        method: "PATCH",
        data: {
          tgId: dialog.tgId,
          isBanned: false,
        },
        headers: {
          credentials: "include",
        },
      });
      setRemovedBanlistChannel(null);
      dispatch(fetchBanlistChannels());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (removedBanlistChannel) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [removedBanlistChannel]);

  useEffect(() => {
    dispatch(fetchBanlistChannels());
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {removedBanlistChannel && (
        <RemoveBanlistChannelDialogWindow
          hide={() => {
            setRemovedBanlistChannel(null);
          }}
          removeBanlistChannel={() => {
            deleteBanlistChannel(removedBanlistChannel);
          }}
        />
      )}
      <ContentContainer>
        <CustomersInfoCard customPadding={"p-4"}>
          <div className={"flex flex-col gap-5 w-full"}>
            {isLoading ? (
              <Loader />
            ) : (
              <div className={"flex gap-2 flex-col bg-[#141515]"}>
                <div className={"flex"}>
                  <p
                    className={"w-[40px] px-2 py-1  border border-[#515151]"}
                  ></p>
                  <p className={"flex-1 border px-2 py-1 border-[#515151]"}>
                    id канала
                  </p>
                  <p className={"flex-1 border px-2 py-1 border-[#515151]"}>
                    имя канала
                  </p>
                  <p
                    className={"w-[40px] px-2 py-1  border border-[#515151]"}
                  ></p>
                </div>
                <BanlistChannelsList
                  banlistChannels={banlistChannelsData ?? []}
                  deleteBanlistChannel={(banlistChannel) => {
                    setRemovedBanlistChannel(banlistChannel);
                  }}
                />
              </div>
            )}
          </div>
        </CustomersInfoCard>
      </ContentContainer>
    </>
  );
}
