import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { setSelectedVideo, clearSelectedVideo } from "../../../store/slices/selectedVideo-slice";
import SelectedVideoOptions from "./SelectedVideoOptions";
import api from "../../../api/baseApi";
import { Video } from "../../../components/media-components/Video";
import { IMedia } from "../../../common/types/media";

const SelectedVideo = () => {
  const dispatch = useAppDispatch();
  const selectedVideo = useAppSelector((state) => state.selectedVideo) as (IMedia | null);

  const handleThumbnailClick = async (dialogId: string, messageId: string) => {
    await fetchNewMediaURL(dialogId, messageId);
  };

  const fetchNewMediaURL = async (dialogId: string, messageId: string) => {
    try {
      const response: any = await api(`/customers/medias/${dialogId}/${messageId}`, {
        headers: {
          credentials: "include",
        },
      });
      const newMedia = response;
      if (newMedia && selectedVideo !== null) {
        const updatedVideo = {
          ...selectedVideo,
          url: newMedia,
        };
        dispatch(setSelectedVideo(updatedVideo));
      }
    } catch (error) {
      console.log("Error downloading new media", error);
    }
  };

  return (
    <div
      onClick={() => dispatch(clearSelectedVideo())}
      className={`fixed cursor-pointer w-screen z-20 h-screen bg-black bg-opacity-80 top-0 ${!selectedVideo && "hidden"
        } flex justify-center items-center`}
    >
      <div className={"flex flex-col gap-x-5"}>
        {selectedVideo ?
          <Video
            media={selectedVideo}
            clickableThumbnail={false}
            handleThumbnailClick={handleThumbnailClick}
          />
          : null}
        {selectedVideo && <SelectedVideoOptions video={selectedVideo} />}
      </div>
    </div>
  );
};

export default SelectedVideo;
