import React, { useEffect, useState } from "react";
import ContactItem from "../../contacts/ContactItem";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { IDialog } from "../../../../common/types/dialog";
import api from "../../../../api/baseApi";
import { IContact } from "../../../../common/types/telegram-user";
import { useParams } from "react-router-dom";

export default function DialogUsers() {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState<any[]>([]);
  const [participants, setParticipants] = useState<IContact[]>([]);

  const dialog = useAppSelector(
    (state) => state.dialogs.currentDialog
  ) as IDialog;

  const getChatUsers = async () => {
    const response = await api(
      `/parser/customer/${id}/dialogParticipants/${dialog.tgId}`,
      {
        method: "GET",
      }
    );

    setContacts(response as any);
    setIsLoading(false);
  };

  const getChatUsersContacts = async () => {
    const response = await api(`/customers/chatusers-contacts`, {
      method: "POST",
      data: {
        ids: dialog.usersTgIds,
      },
    });
    setParticipants(response as IContact[]);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getChatUsers();
    getChatUsersContacts();
    //eslint-disable-next-line
  }, [dialog]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoading && contacts.length === 0 && participants.length === 0) {
    return <div>No contacts found</div>;
  }

  return (
    <div className="w-full flex flex-col gap-y-4 min-w-[800px]">
      <div className="flex flex-col gap-y-2">
        {contacts.length
          ? contacts.map((contact) => (
              <ContactItem
                contact={contact}
                customerId={dialog.customerId}
                key={contact.tgId}
              />
            ))
          : participants.map((contact) => (
              <ContactItem
                contact={contact}
                customerId={dialog.customerId}
                key={contact.tgId}
              />
            ))}
      </div>
    </div>
  );
}
