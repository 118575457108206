import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

interface UseURLParams {
    page: number;
    search: string;
    setPage: (page: number) => void;
    setSearch: (search: string) => void;
}

export function useURLParams(defaultPage = 1, defaultSearch = ""): UseURLParams {
    const [searchParams, setSearchParams] = useSearchParams();

    const initialPage = parseInt(searchParams.get("page") || `${defaultPage}`, 10);
    const initialSearch = searchParams.get("search") || defaultSearch;

    const [page, setPage] = useState(initialPage);
    const [search, setSearch] = useState(initialSearch);

    useEffect(() => {
        const params = new URLSearchParams();
        params.set("page", `${page}`);
        if (search) params.set("search", search);
        setSearchParams(params);
    }, [page, search, setSearchParams]);

    return { page, search, setPage, setSearch };
}