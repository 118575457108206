import React from "react";
import { ReactComponent as MtsLogo } from "../assets/MTS_Link_ru.svg";

interface MtsBasePageProps {
  section: React.ComponentType;
  props?: any;
}

export default function MtsBasePage({
  section: Section,
  props,
}: MtsBasePageProps) {
  return (
    <div className="flex flex-col items-center min-h-screen w-full">
      <header className="text-white px-4 pt-8 w-full relative flex flex-col items-center">
        <MtsLogo className="w-48 h-32" />
      </header>
      <main className="flex-grow bg-white px-4 py-8 w-full flex flex-col items-center justify-start text-black">
        <Section {...props} />
      </main>
      <footer className="text-black px-4 pb-8 w-full relative flex flex-col items-center">
        <p className="text-center text-sm">
          © Webinar Group, 2008–2025. «МТС Линк», «Webinar», «We.Study», «Comdi»
        </p>
      </footer>
    </div>
  );
}
