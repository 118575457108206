import React, { FC } from "react";
import LinkForm from "./LinkForm";
import { IoMdClose } from "react-icons/io";
import { CustomerLink } from "../../../../common/types/customer-link";

interface LinksDialogWindowProps {
  hideCb: () => void;
  hideAndUpdate: (link: CustomerLink) => void;
  handlerUpdate: () => Promise<void>;
}

const LinksDialogWindow: FC<LinksDialogWindowProps> = ({
  hideCb,
  hideAndUpdate,
  handlerUpdate,
}) => {
  return (
    <div
      onClick={hideCb}
      className={
        "absolute w-screen h-screen bg-black bg-opacity-70 top-0 cursor-pointer flex justify-center items-center"
      }
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-[#161616]  text-[#d7d7d7] cursor-default rounded-md max-w-[750px]  border-2 border-[#252627] w-full max-h-[600px] flex flex-col items-center`}
      >
        <div className={"w-full flex justify-end px-[25px] pt-[25px]"}>
          <button
            onClick={hideCb}
            className={
              "hover:bg-gray-500 transition-colors rounded-md p-2 flex justify-center items-center"
            }
          >
            <IoMdClose size={25} />
          </button>
        </div>
        <LinkForm
          hideAndUpdate={hideAndUpdate}
          handlerUpdate={handlerUpdate}
          hideCb={hideCb}
        />
      </div>
    </div>
  );
};

export default LinksDialogWindow;
