import React, { FC, useEffect, useState } from "react";
import { CustomerStatus, ICustomer } from "../../../common/types/customer";
import { Link } from "react-router-dom";
import Avatar from "../../../components/media-components/Avatar";
import Counter from "../../../components/Counter";
import { IoChatbubble } from "react-icons/io5";
import { MdPermMedia, MdOutlineDelete } from "react-icons/md";
import { RiContactsBook2Fill } from "react-icons/ri";
import { isValidImageUrl } from "../../../common/util";
import { useAppSelector } from "../../../hooks/reduxHooks";
import ReparseButton from "../../../components/buttons/ReparsingCustomerButton";
// import SubscribeButton from "../../../components/buttons/SubscribeButton";

interface CustomerListItemProps {
  customer: ICustomer;
  onDelete: (tgId: string) => void;
}

const CustomerListItem: FC<CustomerListItemProps> = ({
  customer,
  onDelete,
}) => {
  const customers = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;

  const [borderClass, setBorderClass] = useState("");

  const userAvatar = customer.userAvatars?.find(isValidImageUrl) || "";

  const getStatusColor = (status: CustomerStatus) => {
    switch (status) {
      case CustomerStatus.AVAILABLE:
        return "border-green-500";
      case CustomerStatus.PROCESSING:
        return "border-yellow-500";
      default:
        return "border-red-500";
    }
  };

  useEffect(() => {
    const color = getStatusColor(customer.status);
    setBorderClass(`border-2 ${color}`);
  }, [customer.status]);

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) {
      return "Дата неизвестна";
    }
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <div
      className="relative flex justify-between items-center rounded-md w-full cursor-pointer text py-[10px] px-[20px] transition-colors hover:bg-[#3d3e3f] bg-[#272727] text-[#d7d7d7]"
      title={`Последнее обновление: ${formatDate(customers?.updatedAt)}`}
    >
      <Link
        to={`/customers/${customer.tgId}`}
        className="absolute inset-0 z-10"
      />
      <div className="flex items-center gap-x-4">
        <div className={`rounded-full w-[48px] h-[48px] ${borderClass}`}>
          <Avatar url={userAvatar} />
        </div>
        <div className="flex gap-x-2 font-semibold items-center">
          <p>@{customer.username}</p>
          <p className="text-[#161616]">|</p>
          <p>
            <span>
              {customer?.firstName ?? ""} {customer?.lastName || ""}
            </span>
          </p>
        </div>
      </div>
      <div className="flex gap-x-2 items-center">
        <Counter
          count={customer.dialogsCount as number}
          icon={<IoChatbubble size={20} />}
        />
        <Counter
          count={customer.mediasCount as number}
          icon={<MdPermMedia size={20} />}
        />
        <Counter
          count={(customer.contactsCount as number) || 0}
          icon={<RiContactsBook2Fill size={20} />}
        />

        <ReparseButton customerId={customer.tgId} />
        {/* <SubscribeButton customer={customer} /> */}

        <button
          onClick={() => onDelete(customer.tgId)}
          className="flex items-center gap-x-2 text-white p-2 transition-colors rounded-lg hover:bg-red-700 bg-red-600 z-30 relative"
        >
          <MdOutlineDelete size={20} />
        </button>
      </div>
    </div>
  );
};

export default CustomerListItem;
