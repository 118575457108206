import React, {FC} from 'react';

interface UserItemButtonProps {
    icon: React.ReactNode;
    cb: () => void;
}

const UserItemButton: FC<UserItemButtonProps> = ({cb, icon}) => {
    return (
        <button onClick={cb} className={'px-2 py-1 hover:bg-[#3d3e3f] w-[40px] border border-[#252627]  h-[40px] flex justify-center items-center'}>
            {icon}
        </button>
    );
};

export default UserItemButton;