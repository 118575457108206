import React, {FC, ReactNode} from 'react';

interface ContentContainerProps {
    children: ReactNode;
}

const ContentContainer: FC<ContentContainerProps> = ({children}) => {
    return (
      <div className={"mx-auto p-4 flex justify-center max-w-[1200px]"}>
        {children}
      </div>
    );
};

export default ContentContainer;