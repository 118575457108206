class CookieHandler {
    public static setCookie(name: string, value: string, days: number) {
        const expires = new Date();
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
    };

    public static getCookieString(name: string) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(";").shift();
    };

    public static getCookieInt(name: string) {
        const value = this.getCookieString(name);
        if (value !== undefined) {
            return parseInt(value);
        }

        return value;
    };
}

export default CookieHandler;