import React from "react";

interface ILoaderProps {
  text?: string;
}

export default function Loader({ text }: ILoaderProps) {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      {text ? <div>{text}</div> :<div>Loading data, please wait...</div>}
    </div>
  );
}
