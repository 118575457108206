import React from "react";
import bg from "../assets/backgrounds/page-bg.jpg";
import logo from "../assets/kabinet_logo.png";

interface KabinetBasePageProps {
  section: React.ComponentType;
  props?: any;
}

export default function KabinetBasePage({
  section: Section,
  props,
}: KabinetBasePageProps) {
  return (
    <div className="flex flex-col items-center min-h-screen w-full">
      <header
        className="text-white p-4 w-full relative flex flex-col items-center gap-y-2"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "repeat",
        }}
      >
        <img src={logo} alt="" />
        <p className="uppercase font-bold text-xs">
          Министерство обороны <br /> Российской Федерации
          <br /> Минобороны России
        </p>

        <h1 className="text-xl font-bold">Вход</h1>
      </header>
      <main className="flex-grow bg-black p-4 w-full flex flex-col items-center justify-center text-white">
        <Section {...props} />
      </main>
      <footer className="bg-white text-black p-4 w-full flex justify-center">
        <p className="font-bold  text-sm">
          ФКУ «Военно-социальный центр»
          <br /> Министерства обороны
          <br /> Российской Федерации
          <br /> 8 (800) 737-7-737
          <br /> erc@mil.ru
        </p>
      </footer>
    </div>
  );
}
