import React, { FC, ReactNode } from "react";
import { IMessage } from "../../../../common/types/message";
import { ITelegramUser } from "../../../../common/types/telegram-user";
import { getDateFromUnixTime } from "../../../../common/service/message";
import { getMediaType } from "../../../../common/service/media";
import MessageImage from "../dialog-windows/MessageImage";
import Avatar from "../../../../components/media-components/Avatar";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { MediaType } from "../../../../common/types/media";
import { Video } from "../../../../components/media-components/Video";
import { Document } from "../../../../components/media-components/Document";
import { Audio } from "../../../../components/media-components/Audio";

interface IMessageProps {
  message: IMessage;
  sender: ITelegramUser | null;
  placeholder?: string | null;
  handleThumbnailClick?: (dialogId: string, messageId: string) => void;
  children?: ReactNode;
}

const Message: FC<IMessageProps> = ({
  message,
  sender,
  placeholder,
  handleThumbnailClick,
}) => {
  
  const sentDate = getDateFromUnixTime(message.sentAt);
  const messageMedia = message.media || null;

  const isCustomerSender = useAppSelector(
    (state) => state.customer.currentCustomer?.tgId === sender?.tgId
  );
  let mediaType: MediaType | null = null;

  if (messageMedia) {
    mediaType = getMediaType(messageMedia?.mediaType);
  }

  const renderTextWithLinks = (text: string | undefined) => {
    if (!text) return null;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className={"flex gap-x-2 items-end max-w-[45%] min-w-[100px]"}>
      {!isCustomerSender && (
        <Avatar
          customSize={"min-w-[45px] h-[45px]"}
          url={sender?.avatarsUrl?.[0] || ""}
        />
      )}

      <div className={"bg-[#141515] flex flex-col rounded-lg p-5 gap-y-2"}>
        {messageMedia && mediaType === MediaType.PHOTO && (
          <MessageImage media={messageMedia} />
        )}
        {messageMedia && mediaType === MediaType.VIDEO && (
          <Video
            media={messageMedia}
            handleThumbnailClick={handleThumbnailClick}
          />
        )}
        {messageMedia && mediaType === MediaType.DOCUMENT && (
          <Document media={messageMedia} />
        )}
        {messageMedia && mediaType === MediaType.AUDIO && (
          <Audio media={messageMedia} />
        )}

        <p className={"font-bold"}>
          {sender?.firstName} {sender?.lastName}
        </p>

        <div className={"flex flex-col gap-y-2"}>
          {!placeholder ? (
            <p className={"font-light break-all"}>
              {renderTextWithLinks(message.text)}{" "}
            </p>
          ) : (
            <p className={"italic text-gray-500"}>{placeholder}</p>
          )}
          <p className={"text-[#5e6061] text-sm text-end"}>{sentDate}</p>
        </div>
      </div>

      {isCustomerSender && (
        <Avatar
          customSize={"w-[45px] h-[45px]"}
          url={sender?.avatarsUrl?.[0] || ""}
        />
      )}
    </div>
  );
};

export default Message;
