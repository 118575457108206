import React, { FC, useEffect, useState } from "react";
import { IMedia, IMediaData } from "../../../common/types/media";
import { useNavigate } from "react-router-dom";
import api from "../../../api/baseApi";
import Avatar from "../../media-components/Avatar";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { setSelectedImage } from "../../../store/slices/selectedImage-slice";

interface SelectedImageOptionsProps {
  image: IMedia;
}

const SelectedImageOptions: FC<SelectedImageOptionsProps> = ({ image }) => {
  const naviagate = useNavigate();
  const [mediaData, setMediaData] = useState<IMediaData | null>(null);
  const dispatch = useAppDispatch();
  const fetchMediaData = async () => {
    const result = await api<IMediaData>(`customers/media-info/${image.id}`);
    setMediaData(result);
  };

  const goToDialog = () => {
    dispatch(setSelectedImage(null));
    //@ts-ignore
    // prettier-ignore
    naviagate(`/customers/${image.customerId}/data/dialogs/${mediaData?.dialog['_id']}/?imageId=${image.messageId}`);
  };
  useEffect(() => {
    fetchMediaData();
  }, []);

  return (
    <div
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      className={
        "flex bg-[#161616] text-[#d7d7d7] rounded-b-md min-h-[80px] px-6 py-6 cursor-default"
      }
    >
      {!mediaData ? (
        <div className={"w-full h-full flex items-center justify-center"}>
          ...Загрузка
        </div>
      ) : (
        <div className={"flex gap-x-5 justify-center items-center"}>
          {/*@ts-ignore*/}
          <Avatar
            url={mediaData["dialog"]["avatarsUrl"]?.[0] ?? ""}
            customSize={"w-[70px] h-[70px]"}
          />
          <div className={"flex flex-col gap-y-1"}>
            <span>{mediaData.dialog.title}</span>
            <button
              onClick={goToDialog}
              className={
                "px-2 bg-blue-600 hover:bg-blue-500 transition-colors py-2 rounded-md"
              }
            >
              Перейти
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedImageOptions;
