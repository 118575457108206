import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { RootState } from "../../store/store";
import { Navigate, Outlet } from "react-router-dom";
import { logout, setUser } from "../../store/slices/auth-slice";
import AdminNavigation from "../../components/layouts/AdminNavigation";
import AdminBackground from "../../components/layouts/AdminBackground";
import SelectedImage from "../../components/layouts/selected-image/SelectedImage";
import { getTokens } from "../../common/util/tokens";
import api from "../../api/baseApi";
import { ISystemUser } from "../../common/types/user";
import { IRole } from "../../common/types/role";

interface IUserWithRoles extends Omit<ISystemUser, "role"> {
  role: IRole;
}

export default function AdminRoute() {
  const authState = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const tokens = getTokens();
  const isAuth = !!tokens && authState.isAuthenticated;

  const getMe = async () => {
    try {
      const user: IUserWithRoles = await api(`/auth/admin/me`, {
        headers: {
          Authorization: `Bearer ${tokens?.access_token}`,
        },
      });
      if (user) {
        dispatch(
          setUser({
            id: user.id,
            nickname: user.nickname,
            role: user.role.name,
          })
        );
      }
    } catch (error) {
      console.error("Error fetching user data", error);
      dispatch(logout());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const tokens = getTokens();
    if (!tokens) {
      dispatch(logout());
      setLoading(false);
    } else {
      getMe();
    }
    //eslint-disable-next-line
  }, [isAuth, dispatch]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="loader"></div>
      </div>
    );
  }

  return isAuth ? (
    <>
      <SelectedImage />
      <AdminBackground>
        <AdminNavigation />
        <Outlet />
      </AdminBackground>
    </>
  ) : (
    <Navigate to="/admin/sign-in" />
  );
}
