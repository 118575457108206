import NavigationButton from "../buttons/NavigationButton";

import ContentContainer from "./ContentContainer";

import { useDispatch } from "react-redux";
import { logout } from "../../store/slices/auth-slice";
import { useAppSelector } from "../../hooks/reduxHooks";
import { RootState } from "../../store/store";
import UsersIcon from "../../assets/icons/UsersIcon";
import CustomerIcon from "../../assets/icons/CustomerIcon";
import BunTriangleIcon from "../../assets/icons/BunTriangleIcon";
import LinkIcon from "../../assets/icons/LinkIcon";
import ExitIcon from "../../assets/icons/ExitIcon";

const AdminNavigation = () => {
  const dispatch = useDispatch();
  const authState = useAppSelector((state: RootState) => state.auth);

  return (
    <ContentContainer>
      <nav className={"flex w-full justify-between py-5"}>
        <div className={"flex gap-x-6"}>
          <NavigationButton
            text={"Аккаунты"}
            href={"/customers"}
            icon={<UsersIcon />}
          />
          <NavigationButton
            text={"Линки"}
            href={"/customers-links"}
            icon={<LinkIcon />}
          />
          {authState?.user?.role === "admin" ||
          authState?.user?.role === "superadmin" ? (
            <NavigationButton
              text={"Пользователи*"}
              href={"/users"}
              icon={<CustomerIcon />}
            />
          ) : null}
          <NavigationButton
            text={"Бан лист каналов"}
            href={"/channels-banlist"}
            icon={<BunTriangleIcon />}
          />
        </div>
        <div className="flex items-center gap-x-4">
          {authState?.user && (
            <div className="flex flex-col items-end">
              <span className="text-sm font-medium text-white">
                {authState?.user.nickname}
              </span>
              <span className="text-xs text-white">{authState?.user.role}</span>
            </div>
          )}
          <NavigationButton
            text={"Выход"}
            href={"/admin/sign-in"}
            icon={<ExitIcon />}
            cb={() => {
              dispatch(logout());
            }}
          />
        </div>
      </nav>
    </ContentContainer>
  );
};

export default AdminNavigation;
