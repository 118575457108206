import React, { useEffect, useState } from "react";
import api from "../../../api/baseApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { setCustomer } from "../../../store/slices/customer-slice";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import { ICustomer, ICustomersResponse } from "../../../common/types/customer";
import CustomersList from "./CustomersList";
import { useLoading } from "../../../providers/LoadingContext";
import { toast } from "react-toastify";
import CustomerSceleton from "../../../components/sceletons/CustomerSceleton";
// import useScrollPosition from "../../../common/util/restorePosition";

export default function CustomersPage() {
  const { isLoading, setLoading } = useLoading();
  const dispatch = useAppDispatch();
  const customersData = useAppSelector((state) => state.customer);

  const [page, setPage] = useState(1);
   const limit = 20;

   const handleNextPage = () => {
       setPage(page + 1);
   };

   const handlePrevPage = () => {
       setPage(page - 1);
   };

  const fetchCustomers = async (page:number) => {
    try {
      setLoading(true);
      const customers: ICustomersResponse = await api(
        `/customers`,
        {},
        {
          limit: `${limit}`,
          page: `${page}`,
        }
      );
      if (customers.customers?.length) {
        dispatch(setCustomer(customers));
        setLoading(false);
      }
    } catch (error) {
      console.log("Error fetching customers", error);
      toast.error("Ошибка при загрузке клиентов");
    } finally {
      setLoading(false);
    }
  };

  const clearCache = async () => {
    try {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName))
      );
    } catch (error) {
      console.error("Ошибка при очистке кэша:", error);
    }
  };

  const deleteCustomer = async (customerId: string) => {
    try {
      await clearCache();

      await api(`customers/dialog/${customerId}`, {
        method: "DELETE",
        headers: {
          credentials: "include",
        },
      });
      const newData: ICustomersResponse = {...customersData, customers:customersData.customers.filter(
        (customer) => customer.tgId !== customerId
      ), totalPages: customersData.totalPages ?? 0};
      dispatch(setCustomer(newData));
      toast.success("Клиент успешно удалён");

      fetchCustomers(page);
    } catch (error) {
      console.log("Error deleting customer", error);
      toast.error("Ошибка при удалении клиента");
    }
  };
  useEffect(() => {
    // if (!customersData.length) {
    fetchCustomers(page);
    // }
    //eslint-disable-next-line
  }, [page]);

  const sceletons = [...new Array(20)].map((_, index) => <CustomerSceleton key={index} />)

  return (
    <ContentContainer>
        <CustomersInfoCard customPadding={"p-[30px]"}>
            <div className="flex flex-col w-full max-h-[75vh] overflow-y-auto">
                {isLoading ? (
                    <ul className={"flex flex-col gap-y-2 w-full"}>
                        {sceletons}
                    </ul>
                ) : (
                    <>
                        <CustomersList
                            customers={customersData.customers}
                            onDelete={deleteCustomer}
                        />
                        <div className="flex justify-between mt-4">
                            <button
                                onClick={handlePrevPage}
                                disabled={page === 1}
                                className="px-4 py-2 bg-gray-200 text-black rounded disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                            Prev Page
                            </button>
                            <span>
                            Page {page} of {customersData.totalPages}
                            </span>
                            <button
                                onClick={handleNextPage}
                                disabled={customersData.customers.length < limit}
                                className="px-4 py-2 bg-gray-200 text-black rounded disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                            Next Page
                            </button>
                        </div>
                    </>
                )
                }
            </div>
        </CustomersInfoCard>
    </ContentContainer>
  );
}
